import React from 'react';
import fire from '../config';   
import axios from 'axios';
import firebase from 'firebase';

class SignUp extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            email:'',
            customWords: [],
            message: '',
            success: false
        };

    }

    componentDidMount(){

      

    }

    
    setupNewUser = (idToken, socialUser) => {
        this.props.toggleLoading();
        localStorage.setItem("token", idToken);
        let date = new Date();
        localStorage.setItem("timeStamp", date);
        let newUser, newTranscript;

        if(socialUser){
            newUser = {
                username: socialUser.displayName,
                email: socialUser.email,
                profileImgLink: socialUser.photoURL,
                idToken
            }
        }else{
            newUser = {
                username: this.state.username,
                email: this.state.email,
                idToken
            }
        }

       
        let currUser
        axios.post('db/users', newUser)
            .then((response)=>{
                currUser = fire.auth().currentUser;
                this.setState({success:true});
            }).catch(err=>{
                console.log(err);
            })
        
        
    }
    

    handleChange = (name, value) => {
        this.setState({[name]: value });
    }

    handleGoogleAuth = (event) => {
        event.preventDefault();
        let provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {

            let user = result.user;

            fire.auth().currentUser.getIdToken(true).then((idToken) => {

                this.setupNewUser(idToken, user);

              }).catch(function(error) {
                console.log(error);
              }); 

            
            
          }).catch(function(error) {
            console.log(error);
            // Handle Errors here.
            alert("auth failed");
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.props.toggleLoading();

        fire.auth().createUserWithEmailAndPassword(this.state.email,this.state.password)
            .then((user)=>{
                fire.auth().currentUser.getIdToken(true).then((idToken) => {

                    this.setupNewUser(idToken);

                  }).catch(function(error) {
                    console.log(error);
                  }); 

            })
            .catch((err) => {
                console.log(err);
                this.props.toggleLoading();
                this.setState({message: '*'+err.message});
            })
    
    }

    render(){

        return(
            <div className="SignUpBox">

                <form onSubmit={this.handleSubmit}>
                    <div className="form-row">
                    {/* <div className="form-group col">
                        <label for="">First Name</label>
                        <input type="text" value={this.state.firstname} required onChange={evt=>this.handleChange('firstname', evt.target.value)} className="form-control" id="firstnameSignUp" aria-describedby="" placeholder="First name"/>
                    
                    </div> */}
                    <div className="form-group col">
                        <label for="">UserName</label>
                        <input type="text" value={this.state.username} required onChange={evt=>this.handleChange('username', evt.target.value)} className="form-control" id="usernameSignUp" aria-describedby="" placeholder="UserName"/>
                    
                    </div>
                    </div>
                    
                    <div className="form-group">
                        <label for="">Email</label>
                        <input type="email" value={this.state.email} required onChange={evt=>this.handleChange('email', evt.target.value)} className="form-control" id="emailSignUp" aria-describedby="" placeholder="Email"/>
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    <div className="form-group">
                        <label for="">Password</label>
                        <input type="password" value={this.state.password} required onChange={evt=>this.handleChange('password', evt.target.value)}  className="form-control" id="passwordSignUp" placeholder="Password"/>
                    </div>
                    
                    <div>
                    { this.state.success ? (
                        <div className="successMessage">
                            Logging in...
                            {/* Please Check Your Email And Verify Your Account! */}
                        </div>
                    ) : (
                        <div className="authMessage">{this.state.message}</div>
                    )}
                    </div>

                    {/* <button className="btn socialButtons" onClick={evt=>this.handleGoogleAuth(evt)}><img src="images/google-logo.svg" title="Sign Up with Google" width="30px"/><span id="signUpWithGoogle">Sign Up with Google</span></button> */}
                    {/* <button className="btn socialButtons" onClick={evt=>this.handleFacebookAuth(evt)}><img src="images/facebook-logo.svg" title="Sign Up with Facebook" width="30px"/></button> */}

                    <br/>
                    <br/>

                    <button type="submit" className="btn brownButton">Let's Go</button>
                    
                </form>



            </div>
        );
    }
}

export default SignUp;