import React from 'react';
import Login from './Login';
import SignUp from './SignUp';
import {
    Row,
    Col
 } from 'reactstrap';
 import Spinner from './Spinner';

class Authentication extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    LoginClick = () => {
        this.setState ({
            signUp: false
        });
    }

      
    SignUpClick = () => {
        this.setState ({
            signUp: true
        });  
    }

    toggleLoading = () => {
        this.setState({loading: !this.state.loading});
    }

    render(){
        return(

            <div className="authBox" >
                <div className="loginButtons">
                    <Row>
                        <Col style={{"padding":"0"}}>
                            <button onClick={this.LoginClick} className={this.state.signUp === undefined ? (this.props.signUp  ? "btn btn-light authButtons" : "btn btn-secondary authButtons") : (this.state.signUp  ? "btn btn-light authButtons" : "btn btn-secondary authButtons")}>
                                Log in
                            </button>
                        </Col>
                        <Col style={{"padding":"0"}}>
                            <button onClick={this.SignUpClick} className={this.state.signUp === undefined ? (this.props.signUp  ? "btn btn-secondary authButtons " : "btn btn-light authButtons") : (this.state.signUp  ? "btn btn-secondary authButtons " : "btn btn-light authButtons")}>
                                Sign Up
                            </button>
                        </Col>
                    </Row>
                    
                
                </div>
                <hr></hr>
                
                <div >                              
                    {
                        this.state.signUp === undefined ? (
                            this.props.signUp ? (
                                <SignUp toggleLoading={this.toggleLoading} />
                            ) : (
                                <Login toggleLoading={this.toggleLoading} emailVerified={this.props.emailVerified}/>
                            )
                        ) : (
                            this.state.signUp ? (
                                <SignUp toggleLoading={this.toggleLoading} />
                            ) : (
                                <Login toggleLoading={this.toggleLoading} emailVerified={this.props.emailVerified}/>
                            )
                        ) 
                    }
                    {
                        this.state.loading && (
                            <Spinner/>
                        )
                    }
                    
                </div>
                
            </div>     
            
        )
    }
    
    
}

export default Authentication;