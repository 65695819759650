import React from 'react';
// import CustomWords from './CustomWords';    
import '../App.css';
import axios from 'axios';
import fire from '../config';
// import WatsonSpeech from 'watson-speech';
// import Typist from 'react-typist';
import Authentication from './Authentication';
import {
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter
 } from 'reactstrap';
 import {Helmet} from "react-helmet";

let stream;
var user;

class LandingPage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            token: '',
            signup: false,
            final: true,
            currMsg: '',
            isRecording: false,
            currUser: {},
            fireuser: {},
            isOpen: false,
            modal: false,
            authModal: false
        }
    }

    componentDidMount(){
        // user = fire.auth().currentUser;
        // this.setState({fireuser: user});
        // this.getUser();
        // this.getToken();
        this.setState({cookiesAccepted: sessionStorage.getItem("cookiesAccepted")});
    }

    acceptCookies = () => {
        this.setState({cookiesAccepted: true});
        sessionStorage.setItem("cookiesAccepted", true);
    }

    toggleAuthModal = () => {
        this.setState({
            authModal: !this.state.authModal,
            signUp: "true"
        });
    }

    closeModal = () => {
        this.setState({authModal: false});
    }

    componentWillUnmount(){
        if (this.state.isRecording){
            this.stopRecording();
        }
    }

    getUser = () => {
        axios.get(`db/users/one?email=speakupappteam@gmail.com`)
            .then((response)=>{
                this.setState({currUser: response.data[0]});
                
            }).catch(err=>{
                console.log(err);
            })
         
      }
    
    getToken = () => {
        axios.get(`/api/token`)
        .then((response)=>{
            this.setState({token: response.data});
            // console.log(response.data);
            
        }).catch(err=>{
            console.log(err + "err");
        })
    }
      
    LoginClick = () => {
        this.setState ({
            signup: false
        });
    }

    
    SignUpClick = () => {
        this.setState ({
            signup: true
        });
        
    }

    
    

    


    render(){

        return(
            
            <div id="mainLanding">
                
               
                

                {/* <iframe src="videos/gamePlay2.webm" allow="autoPlay"/> */}

                            
                    {/* <div id="gamePlayVideoBox">
                        <img id="charactersImage" className="landingPageImages" src="images/miscallaneous_assets/charactersImage.png"></img>
                    </div> */}
                    
                    <div id="gamePlayVideoBox">
                        <br/><br/><br/><br/>
                        <h2 id="gameVideoTitle">Do You Have What It Takes?</h2>
                        <video id="gamePlayVideo" src="videos/gamePlay2.webm" alt="Gameplay Video" autoPlay muted loop controls></video>
                    </div>

                    <div className="row">
                    <div className="col-lg-2">

                    </div>
                    <div className="col-lg-8">
                        <div className="container">
                            <div id="discordBox">
                                <h2 className="backgroundLightBrown">Connect with the Community!</h2>
                                <iframe id="discordIFrame" src="https://discordapp.com/widget?id=760640511622512663&theme=dark" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        {/* <div id="182044168">
                            <Helmet>
                                <script type="text/javascript">
                                    {`try {
                                        window._mNHandle.queue.push(function (){
                                            window._mNDetails.loadTag("182044168", "970x90", "182044168");
                                        });
                                    }
                                    catch (error) {}
                                    `}
                                </script>
                            </Helmet>
                        </div> */}
                    </div>
                    </div>
                    
                <div className="container">
                    <div>
                        <div className="row section">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 landingGeneral backgroundLightBrown">
                                <h2>Choose a Character Wisely</h2>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 col-12 landingGeneral backgroundLightGray">
                                <img className="landingPageImages" src="images/character_cards/cardsFan.png"></img>
                            </div>
                        </div>
                        
                    </div>

                    <div>
                        <div className="row section">
                            <div className="col-lg-8 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 landingGeneral backgroundLightGray">
                                <img className="landingPageImages" src="images/miscallaneous_assets/goldAndCards.png"></img>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1 landingGeneral backgroundLightBrown">
                                <h2>Earn Gold <br/>OR<br/> Draw A Card</h2>
                            </div>
                            
                        </div>
                        
                    </div>

                    <div>
                        <div className="row section">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 landingGeneral backgroundLightBrown">
                                <h2>Build Districts and Get the Most Points to Win!</h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 landingGeneral backgroundLightGray">
                                <img className="landingPageImages" id="boardImage" src="images/miscallaneous_assets/boardImage.PNG"></img>
                            </div>

                        </div>
                        
                    </div>

                    

                    {/* <div>
                        <div id="twoToSeven"><h1><strong>2 - 7 Players</strong></h1></div>

                        <div className="row">

                            <div className="col-lg-4 landingGeneral">
                                <h3>Perfect for a family games night OR a virtual hangout with friends</h3>
                            </div>
                            <div className="col-lg-8 characterImageBox">
                                <img  id="charactersImage" src="images/miscallaneous_assets/charactersImage.png"></img>
                            </div>

                        </div>
                        
                    </div> */}

                    {/* <div><button name="login" className="btn playButton" style={{"color":"white","fontWeight":"bold"}} onClick={(evt)=>this.toggleAuthModal(evt.target.name)}>Begin</button></div> */}

                    
                    
                </div>
                {/* <div>
                    <div id="bannerImageBox">
                            <div id="bannerImageText" className='landingGeneral backgroundLightBrown'>
                                <h2>Perfect for a family games night <br/> OR <br/> a virtual hangout with friends</h2>
                            </div>
                            <img className="landingPageImages" id="boardImage" src="images/miscallaneous_assets/bannerImage2.PNG"></img>
                    </div>
                    
                </div> */}
               
            </div>
        );

    }

}

export default LandingPage;