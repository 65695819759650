import React from 'react';
import fire from './config';
import Home from './components/Home';
import LandingPage from './components/LandingPage';
import MainNavbar from './components/MainNavbar';
import axios from 'axios';
import LogRocket from 'logrocket';

// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-132593989-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            user:null,
            emailVerified: false,
            isTop: true
        }
    }

    componentDidMount(){
        
        this.authListener();
        fire.analytics();

        let oldDate = localStorage.getItem("timeStamp");
        if(oldDate){
            let date = new Date();
            let diff = Math.abs(Date.parse(oldDate) - Date.parse(date));
            console.log(diff);
            if(diff >= 3600000){
                fire.auth().signOut();
            }
        }

        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
        });

        LogRocket.init('3w7o2h/speechbud');

    }



    authListener(){
        fire.auth().onAuthStateChanged((user)=>{
            
            if(user) {
               
                // if(user.emailVerified){
                   setTimeout(() => {
                    this.setState({user, emailVerified: true});
                    
                    LogRocket.identify(user.uid, {
                        // name: user.firstname+user.lastname,
                        email: user.email
                    });
                    
                   }, 1000);
                    
                // }
                
            }else {
                this.setState({user:null});
            }
        });
    }

    
   


    render(){

        return(
            <div>
                {
                    this.state.user != null && this.state.emailVerified ? (
                         <div id="portal"><Home fireuser={this.state.user}/></div>
                    ) : (
                        <div>
                            <MainNavbar emailVerified={this.state.emailVerified} isTop={this.state.isTop}/>
                            <LandingPage className="authPage" emailVerified={this.state.emailVerified} user={this.state.user}/>
                        </div>
                       
                    )
                }
            </div>
        )
    }
}


export default App;

