import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCsYObsPWe2aLKue7WNqMI80EH3wBlSnXk",
  authDomain: "citadels-55389.firebaseapp.com",
  databaseURL: "https://citadels-55389.firebaseio.com",
  projectId: "citadels-55389",
  storageBucket: "citadels-55389.appspot.com",
  messagingSenderId: "824427895069",
  appId: "1:824427895069:web:0e01e278d6c088e373a9f4",
  measurementId: "G-82ZC1BBWGL"
};
const fire = firebase.initializeApp(config);

export default fire;