import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavLink,
    NavItem,
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Row,
    Col
 } from 'reactstrap';
 import Authentication from './Authentication';

class MainNavbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          isOpen: false,
          currUser: '',
          modal: false,
          authModal: false,
          signUp: false
        };
      }

      toggle = () => {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

      toggleModal = () => {
        this.setState({
          modal: !this.state.modal
        });
      }

      toggleAuthModal = (name) => {
        let signUp;
        if(name==="login"){
          signUp = false;
        }else if (name==="signup"){
          signUp = true;
        }
        this.setState({
          signUp: signUp,
          authModal: !this.state.authModal
        });
      }

      closeModal = () => {
        this.setState({authModal: false});
      }

    render(){
        return(
            <div>
            <div id="navContainer" style={ {boxShadow: !this.props.isTop && "0px 5px 5px -5px #888888"}}>
                
                    <Navbar className="mainNav fixed-top" color="white" light expand="md">
                          <NavbarBrand href="/"><img src="/images/citadelsLogo-removebg-preview.png" id="logoImg" alt="logo" width="300"/></NavbarBrand>
                      
                              <Nav className="ml-auto navbar" navbar>
                             
                              <NavItem href="">
                              <a href='/Citadels_Rules.pdf' target='_blank'><NavLink name="login" className="btn rulesButton" style={{"color":"white","fontWeight":"bold"}}>Rules</NavLink></a>
                              </NavItem>
                              <NavItem href="">
                              <NavLink name="login" className="btn playButton" style={{"color":"white","fontWeight":"bold"}} onClick={(evt)=>this.toggleAuthModal(evt.target.name)}>Play</NavLink>
                              </NavItem>
                              {/* <NavItem href="">
                              <NavLink name="signup" className="btn signUpButton" style={{"color":"#11738b","fontWeight":"bold"}} onClick={(evt)=>this.toggleAuthModal(evt.target.name)}>Sign Up</NavLink>
                              </NavItem> */}
                          
                              </Nav>
          
                    </Navbar>
                 
                  
            </div>

              
              <Modal isOpen={this.state.authModal} toggle={this.closeModal} size={'sm'} className={this.props.className}>
                    
                <Authentication emailVerified={this.props.emailVerified} signUp={this.state.signUp}/>   

              </Modal>
            </div>


        );
    }

}

export default MainNavbar;