import React from 'react';
import fire from '../config';
import axios from 'axios';
import io from "socket.io-client";
import {characterCards, districtCards} from '../cards';
import {usernames} from '../usernames';
import { Switch } from 'react-router';
import Toggle from 'react-toggle'
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
 } from 'reactstrap';

 import { Beforeunload } from 'react-beforeunload';

let timerHandle;
let queueTimer = null;
// let socket = "http://localhost:3000/";
let socket = "https://citadelsgame.herokuapp.com/";
// import Dashboard from './Dashboard';

class Main extends React.Component {

    verifyToken = () => {
        let oldDate = localStorage.getItem("timeStamp");
        if(oldDate){
            let date = new Date();
            let diff = Math.abs(Date.parse(oldDate) - Date.parse(date));
            if(diff >= 3600000){
                this.state.fireuser.getIdToken(/* forceRefresh */ true).then((idToken) => {
                    localStorage.setItem("token", idToken);
                    let date = new Date();
                    localStorage.setItem("timeStamp", date);
                    return idToken;
                  }).catch(function(error) {
                    console.log(error);
                  });
    
            }else{
                return localStorage.getItem("token");
            }
        }
    }

    logout(){ 
        fire.auth().signOut();
        socket.emit("removeFromOnlineList", this.state.currentUser);
        axios.put(`db/onlineList/remove`, {user: this.state.fireuser.email, idToken: this.verifyToken()})
        .then((response)=>{
            
        }).catch(err=>{
            console.log(err);
        })
    }

    constructor(props){
        super(props);
        
        this.state = {
            fireuser: {},
            game:{gameId:"temp", currentKing:""},
            tradePile: [],
            drawingGold:true,
            waitingForAction:true,
            playerIndex: 0,
            actionLog:[],
            exitedGame:false,
            showTimer: false,
            messages: [],
            currentChatMessage:"",
            useTimer:false,
            queueTime: 0,
            timer: "-",
            numberOfPlayers: 2,
            rated: false,
            privateGame: false,
            publicGames: [],
            leaderboardPlayers: [],
            gameEnded: false,
            abandoned: true,
            onlineList: [],
            sentChallengesList: [],
            receivedChallengesList: [],
            messageCounter: 0
        }
    }

    // handleWindowBeforeUnload = (ev) => {
    //     // ev.preventDefault();
    //     // if(this.state.game && !this.state.game.gameStarted){
    //     //     if(this.state.game.partyLeader === this.state.currentUser.email){
    //     //         this.deleteGame();
    //     //     }else{
    //     //         this.leaveGame();
    //     //     }
    //     // }
    //     if(this.state.game && this.state.game.rated && this.state.game.gameStarted && !this.state.game.gameEnded){
    //         // if(window.confirm("are u sure")){
    //         //     return true
    //         // }else{
    //         //     return void(0);
    //         // }
    //         this.endGame(this.state.game, this.state.currentUser);

    //         // ev.returnValue = "are u sure?";
    //         // return "Are you sure to leave this page?";
    //     }
    // }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            let val = this.handleWindowBeforeUnload(ev);
            // if(this.state.abandoned){
            //     this.endGame(this.state.game, this.state.currentUser);
            // }
            // console.log(val);
            //
            return val;
        });
    };

    componentDidMount(){
        // this._isMounted = true;
        let user = fire.auth().currentUser;
        let email = user.email;
        this.setState({fireuser: user});
        // const SOCKET_IO_URL = "http://localhost:3000/";
        const SOCKET_IO_URL = "https://citadelsgame.herokuapp.com/";
        socket = io({uri: SOCKET_IO_URL, auth: {user:email}});

        this.setupBeforeUnloadListener();

        // window.addEventListener("beforeunload", (ev) => 
        // {  
        //     ev.preventDefault();
        //     return ev.returnValue = 'Are you sure you want to close?';
        // });

        this.scrollToBottom();
        
        this.setState({actionLog:[], exitedGame:false});
        
        let encodedEmail = encodeURIComponent(user.email);

        let data = {
            email1: user.email,
            idToken: this.verifyToken()
        }
        axios.put(`db/gameQueue`, data)
        .then((response)=>{
            clearInterval(queueTimer);
            queueTimer = null;
        }).catch(err=>{
            console.log(err);
        })

        axios.get(`db/users/one?email=${encodedEmail}&idToken=${this.verifyToken()}`)
        .then((response)=>{
            let currentUser = response.data[0];
            this.setState({currentUser});
            if(currentUser.currentGame != ""){
                this.getGame();
            }
            
          
        }).catch(err=>{
            console.log(err);
        })

        axios.get(`db/onlineList?idToken=${this.verifyToken()}`)
        .then((response)=>{
            let onlineList = response.data[0].list;
            onlineList = onlineList.reverse();
            this.setState({onlineList});
        }).catch(err=>{
            console.log(err);
        })

        socket.on("connect", ()=>{
            // console.log(this.state.currentUser, "HELLO");
            let encodedEmail = encodeURIComponent(this.state.fireuser.email);
            axios.get(`db/users/one?email=${encodedEmail}&idToken=${this.verifyToken()}`)
            .then((response)=>{
                let currentUser = response.data[0];
                
                socket.emit("addToOnlineList", currentUser);
                axios.put(`db/onlineList/add`, {user: currentUser, idToken: this.verifyToken()})
                .then((response)=>{
                    
                }).catch(err=>{
                    console.log(err);
                })
              
            }).catch(err=>{
                console.log(err);
            })
            
           
        })

        // socket.on("disconnect", ()=>{
        //     // console.log(this.state.currentUser, "HELLO");
        //     socket.emit("removeFromOnlineList", this.state.fireuser.email);
        //     socket.emit("disconnect", this.state.fireuser.email);

            
            
        // })

        socket.on("addingToOnlineList", data =>{
            
            let currUser = this.state.onlineList.find(elem=>elem.email === data.email);
            if(data.email != this.state.fireuser.email){
                let onlineList = this.state.onlineList;
                if(currUser){
                    onlineList = onlineList.filter(elem => elem.email != data.email);
                }
                onlineList = onlineList.reverse();
                onlineList.push(data);
                onlineList = onlineList.reverse();
                this.setState({onlineList});
            }
            
        })

        socket.on("removingFromOnlineList", data =>{
            let currUser = this.state.onlineList.find(elem=>elem.email === data.email);

            if(currUser){
                axios.put(`db/onlineList/remove`, {user: data, idToken: this.verifyToken()})
                .then((response)=>{
                    
                }).catch(err=>{
                    console.log(err);
                })
                              
                let onlineList = this.state.onlineList;
                onlineList = onlineList.filter(elem => elem.email != data.email);
                this.setState({onlineList});
            }
            
            
        })

        socket.on("playerJoined", data => {
            let newUserEmail = "";
            if(data.newUser){
                newUserEmail = data.newUser.email
            }
            if(this.state.inLobby){
                this.viewLobby();
            }
            if(data.game.gameId === this.state.game.gameId || data.game.gameId === this.state.gameId || (this.state.currentUser && newUserEmail === this.state.currentUser.email)){
                this.setState({inQueue:false, creatingGame:true, queueTime:0});
                clearInterval(queueTimer);
                queueTimer = null;
                this.setGameState(data.game, true); 
            }
        }); 

        socket.on("gameStarted", data => {
            if(this.state.inLobby){
                let publicGames = this.state.publicGames; 
                publicGames = publicGames.filter(game=>game.gameId != data.gameId);
                publicGames.sort((a,b)=>b.timeStamp-a.timeStamp);
                let d = new Date();
                publicGames = publicGames.filter((game)=>{return d.getTime() - game.timeStamp < 500000})
                // console.log(publicGames);
                this.setState({publicGames});
            }
            if(data.gameId === this.state.game.gameId){
                this.setState({exitedGame:false});
                this.setGameState(data);
            }
        }); 

        socket.on("gameDeleted", data => {
            if(this.state.inLobby){
                let publicGames = this.state.publicGames; 
                publicGames = publicGames.filter(game=>game.gameId != data.gameId);
                publicGames.sort((a,b)=>b.timeStamp-a.timeStamp);
                let d = new Date();
                publicGames = publicGames.filter((game)=>{return d.getTime() - game.timeStamp < 500000})
                // console.log(publicGames);
                this.setState({publicGames});
            }
            if(data.gameId === this.state.game.gameId || data.gameId === this.state.gameId){
                this.setState({exitedGame:true, creatingGame:false, actionLog:[], currentAction:"", game:{gameId:"temp"}, gameId:null});
                let body = {
                    email: this.state.currentUser.email,
                    idToken: this.verifyToken()
                }
                axios.put('db/users', body)
                    .then((response)=>{
                        let newUser = response.data;
                        socket.emit("addToOnlineList", newUser);
                        axios.put(`db/onlineList/add`, {user: newUser, idToken: this.verifyToken()})
                        .then((response)=>{
                            
                        }).catch(err=>{
                            console.log(err);
                        })    
                    }).catch(err=>{
                        console.log(err);
                    })   
            }
        }); 

        socket.on("gameStateUpdated", data => {
            if(this.state.inLobby){
                this.viewLobby();
            }
            if(data.gameId === this.state.game.gameId && !this.state.leftGame){
                this.setGameState(data);
            } 
        }); 

        socket.on("receiveNewAction", data => {
            if(data.gameId === this.state.game.gameId){
                if(this.state.currentAction != data.currentAction){
                    let actionLog = this.state.actionLog;
                    actionLog.push(data.currentAction);
                    this.setState({currentAction: data.currentAction, actionLog});  
                }
            }
        }); 

        socket.on("updateInQueue", data => {
            if(this.state.currentUser.email === data.email){
                this.setState({inQueue:false});
            }
        }); 

        socket.on("timerReduced", data => {
            if(data.game.gameId === this.state.game.gameId){
                this.setState({timer:data.time});
            }
        }); 

        socket.on("messageReceived", data => {
            if(data.gameId === this.state.game.gameId){
                let messages = this.state.messages;
                messages.push({name: data.name, message: data.message});
                let messageCounter;
                if (data.name === this.state.currentUser.username){
                    messageCounter = 0;
                }else{
                    messageCounter = this.state.messageCounter+1;
                }
                this.setState({messages, messageCounter});
            }
        }); 

        socket.on("gettingUser", data => {
            if(data === this.state.game.gameId){
                let encodedEmail = encodeURIComponent(this.state.currentUser.email);
        
                axios.get(`db/users/one?email=${encodedEmail}&idToken=${this.verifyToken()}`)
                .then((response)=>{
                    let currentUser = response.data[0];
                    
                    this.setState({currentUser});
                }).catch(err=>{
                    console.log(err);
                })
            }
        }); 

        socket.on("updatingPublicGames", data => {
            if(this.state.inLobby){
                let publicGames = this.state.publicGames; 
                publicGames.push(data);
                publicGames.sort((a,b)=>b.timeStamp-a.timeStamp);
                let d = new Date();
                publicGames = publicGames.filter((game)=>{return d.getTime() - game.timeStamp < 500000})
                this.setState({publicGames});
            }
            
        }); 

        socket.on("receivedChallenge", data => {
            if(data.challenged === this.state.currentUser.email){
                let receivedChallengesList = this.state.receivedChallengesList;
                receivedChallengesList.push(data);
                this.setState({receivedChallengesList, newChallenge:true, showGameInvites:true});
                document.getElementById("notificationSound").play();
                document.title = "New Game Challenge!";
            }
            
        }); 


        // socket.on("gameEnded", data => {
        //     if(data.gameId === this.state.game.gameId){
        //         this.setState({});
        //     } 
        // }); 

    }

    componentDidUpdate(){
        this.scrollToBottom();
    }

    componentWillUnmount(){
        // socket.off("playerJoined");
        // socket.off("gameStarted");
        // socket.off("gameDeleted");
        // socket.off("gameStateUpdated");
        // socket.off("receiveNewAction");
        // socket.off("timerReduced");
        // socket.off("messageReceived");

        // let game = this.state.game;
        // if(game){
        //     if(game.partyLeader === this.state.currentUser.email && !game.privateGame && !game.gameStarted){
        //         this.deleteGame();
        //     }
        // }
    }

    delay = (delay) => {
        return new Promise( res => setTimeout(res, delay));
    }


    updateGameState = (game) => {

        // let game = Object.assign({}, this.state.game);

        let body = {
            game,
            idToken: this.verifyToken(),
        }

        socket.emit("updateGameState", game);

        axios.put('db/games', body)
            .then((response)=>{
                game = response.data;
                // socket.emit("updateGameState", game);
            }).catch(err=>{
                console.log(err);
            })
    }

    setupPhase = async(game) => {
        await this.newAction("Welcome to Citadels");
        await this.newAction("You have all been assigned 4 district cards");
        await this.newAction("Let's begin round 1 and good luck!");
        await this.newAction(`It is <strong>${this.state.game.currentTurn}</strong>'s turn`);

        game.currentPhase = "choosingPhase";
        // this.setState({game});
        this.updateGameState(game);
    }

    choosingPhase = async (game) => {
        
        let characterDeck = game.characterDeck;
        characterDeck.faceDown = [];
        characterDeck.faceUp = [];
        characterDeck.available = characterDeck.main.slice();

        let random;
        let card;
        let cards = [];
        let iterations;
        let index = 8;
        let kingChosen = false;

        
        random = Math.floor(Math.random()*index);
        card = characterDeck.available[random];
        characterDeck.available.splice(random,1);
        characterDeck.faceDown.push(card);
        index--;

        if(card!="king"){
            characterDeck.available.splice(characterDeck.available.indexOf("king"),1);
            index--;
        }else{
            kingChosen = true;
        }
    

        if(game.numberOfPlayers===4){
            iterations = 2;

            for(let i = 0; i<iterations; i++){
                random = Math.floor(Math.random()*index);
                card = characterDeck.available[random];
                cards.push(card);
                characterDeck.available.splice(random,1);
                index--;
            }

            characterDeck.faceUp.push(cards[0]);
            characterDeck.faceUp.push(cards[1]);
                

        }else if (game.numberOfPlayers===5){
            iterations = 1;

            for(let i = 0; i<iterations; i++){
                random = Math.floor(Math.random()*index);
                card = characterDeck.available[random];
                cards.push(card);
                characterDeck.available.splice(random,1);
                index--;
            }

            characterDeck.faceUp.push(cards[0]);
    
        }

        if(!kingChosen){
            characterDeck.available.push("king");
        }
      
        game.currentPhase = "waitingForPlayerChoosing";

        await this.newAction(`<strong>${game.currentTurn}</strong> is choosing a character`);

        // this.setState({game});


        this.updateGameState(game);

    }

    actionPhase = async(game) => {

        if(this.state.currentPlayer.email === game.currentPlayerEmail){
            let currentPlayerEmail = game.currentPlayerEmail === "bot@botmail.com" ? "bot@botmail.com" : this.state.currentUser.email;
            let currPlayer = game.players.find(cPlayer => cPlayer.email === currentPlayerEmail);
            if(currPlayer.turn1Done){
                currPlayer.showChar2 = true;
            }else{
                currPlayer.showChar1 = true;
            }

            if(currPlayer.currentCharacter === "king" || currPlayer.currentCharacter === "bishop"  || currPlayer.currentCharacter === "merchant"  || currPlayer.currentCharacter === "architect"  || currPlayer.currentCharacter === "warlord"){
                game.showCurrentKing = true;
            }

            if(game.currentDead === currPlayer.currentCharacter){

                if(currPlayer.board.purple.includes("hospital") && game.usedAction === false){
                    game.currentPhase = "waitingForPlayerAction";
                    this.updateGameState(game);
                }else{
                    game.currentDead = "";
                    this.nextTurn(game);
                }

            }else if(game.currentStolen === currPlayer.currentCharacter){
                this.giveGoldToThief(game);
            } else{
                let characterName;
                if(currPlayer.turn1Done){
                    characterName = currPlayer.character2;
                }else{
                    characterName = currPlayer.character1;
                }
                characterName = characterName.substr(0,1) + characterName.substr(1);
                await this.newAction(`<strong>${game.currentTurn}</strong> is the ${characterName}!`);
                
                await this.newAction(`<strong>${game.currentTurn}</strong>'s turn to do an action`);

                game.currentPhase = "waitingForPlayerAction";

                this.updateGameState(game);
            }

        }
        

    }


    newAction = async (currentAction) => {
        let game = Object.assign({}, this.state.game);

        // this.state.game.currentAction = currentAction;
        let data = {
            gameId: this.state.game.gameId,
            currentAction
        }

        // this.setState({game});
        socket.emit("sendNewAction", data);  
        await this.delay(1000);

        // let logData = {
        //     gameId: this.state.game.gameId,
        //     action: currentAction,
        //     idToken: this.verifyToken(),
        // }

        // axios.put('db/gameLogs', logData)
        // .then((response)=>{
        //     console.log("RESPONSE", response);
             
        // }).catch(err=>{
        //     console.log(err);
        // })

        
    }

    toggleActionLog = () => {
        this.setState({viewActionLog: !this.state.viewActionLog, viewChat:false});
    }

    scrollToBottom = () => {
        this.messagesEnd && this.messagesEnd.scrollIntoView({ behavior: "smooth",  });
    }

    toggleChat = () => {
        this.setState({viewChat: !this.state.viewChat, viewActionLog:false, messageCounter:0});
    }

    setGameState = async (game, joining) => {

            let currentPlayerEmail = game.currentPlayerEmail === "bot@botmail.com" ? "bot@botmail.com" : this.state.currentUser.email;
            let currentHumanPlayer = game.players.find(player=>player.email === this.state.currentUser.email);
            let currentPlayer = game.players.find(player=>player.email === currentPlayerEmail);
            let partyLeader = game.partyLeader;
  
            let currentCharacter = "";
            let character1 = "";
            let character2 = "";
            let creatingGame = false;
            let handPile = [];

            if(currentHumanPlayer){
                if(game.numberOfPlayers<=3){
                    character1 = characterCards.find(cCard=>cCard.cardName === currentHumanPlayer.character1);
                    character2 = characterCards.find(cCard=>cCard.cardName === currentHumanPlayer.character2);
                    if(currentHumanPlayer.turn1Done){ 
                        currentHumanPlayer.currentCharacter = currentHumanPlayer.character2 ? currentHumanPlayer.character2 : "";
                        currentCharacter = character2;
                    }else{
                        currentHumanPlayer.currentCharacter = currentHumanPlayer.character1 ? currentHumanPlayer.character1 : "";
                        currentCharacter = character1;
                    }
                    
                }else{
                    character1 = characterCards.find(cCard=>cCard.cardName === currentHumanPlayer.character1);
                    currentCharacter = character1; 
                    currentHumanPlayer.currentCharacter = currentHumanPlayer.character1 ? currentHumanPlayer.character1 : "";
                }
                creatingGame = true;
                if(currentHumanPlayer.hand){
                    currentHumanPlayer.hand.map((card, cardIndex)=>{
                        handPile.push({card,cardIndex});
                    })
                }
            }

            if(currentPlayerEmail === "bot@botmail.com"){
                creatingGame = true;
                if(currentPlayer.turn1Done){ 
                    currentPlayer.currentCharacter = currentPlayer.character2 ? currentPlayer.character2 : "";
                }else{
                    currentPlayer.currentCharacter = currentPlayer.character1 ? currentPlayer.character1 : "";
                }
            }

            let players = game.players;

            let currentAction = game.currentAction;

            let currentPhase = game.currentPhase;

            let playerIndex = 0;

            players.map((player,index)=>{
                if(player.email === game.currentPlayerEmail){
                    playerIndex = index;
                }
            })

            if(joining===true){
                this.setState({playerIndex:0});
            }
            
            // console.log(sessionStorage.getItem("timer"));

            let currentTime = 45;
            if(this.state.refresh){
                this.setState({refresh:false});
                game.timerStarted = false;
                currentTime = sessionStorage.getItem("timer");
            }
            let showTimer = false;
            if(game.useTimer){
                showTimer = true;
            }


            this.setState({game, gameId: game.gameId, partyLeader, currentPlayer, currentHumanPlayer, currentCharacter,character1, character2, nextTurn:true, creatingGame, playerIndex, handPile, choseCards:false, endTurnClicked:false, showTimer, destroyingDistrict:false});

            if(!game.gameEnded){
                switch (currentPhase) {
                    case "setup": 
                        this.setupPhase(game);
                        break;
                    case "choosingPhase": 
                        this.choosingPhase(game);
                        break;
                    case "actionPhase":
                        this.actionPhase(game);
                        break;
                    default:
                        if(game.currentPlayerEmail === "bot@botmail.com"){
                            if(game.useTimer && !game.timerStarted){
                                game.timerStarted = true;
                                // this.updateGameState(game);
                                this.timer(game, currentTime);
                            }
                            this.bot(game);
                        } else if(game.useTimer && !game.timerStarted){
                            game.timerStarted = true;
                            this.updateGameState(game);
                            this.timer(game, currentTime);
                        }
                        break;
                }
            }


    }

    timer = (game, time) => {
        //datimer
            clearInterval(timerHandle);
            timerHandle = setInterval(() => {
                if (time<=5){
                    this.setState({endTurnClicked:true});
                }
                if(time===0){
                    let card, fullCard;
                    this.setState({timerExceeded:true, timer: "-"});
                    clearInterval(timerHandle);
                    timerHandle = null;
                    switch (this.state.game.currentPhase) {
                        case "waitingForPlayerChoosing":
                            card = this.state.game.characterDeck.available[0];
                            fullCard = characterCards.find(cCard=>cCard.cardName === card);
                            this.chooseCharacterCard(fullCard);
                            break;
                        case "waitingForPlayerDiscarding":
                            card = this.state.game.characterDeck.available[0];
                            fullCard = characterCards.find(cCard=>cCard.cardName === card);
                            this.discardCharacterCard(fullCard);
                            break;
                        default:
                            this.nextTurn();
                            break;
                    }
                }
                else{
                    time--;
                    sessionStorage.setItem("timer",this.state.timer);
                    socket.emit("reduceTimer", {game,time});
                }
            }, 1000);
       
    }

    //BOT CODE
    bot = (game) => {
        let randNum;
        let currPlayer = game.players.find(cPlayer => cPlayer.email === "bot@botmail.com");
        let opponentPlayer = game.players.find(cPlayer => cPlayer.email != "bot@botmail.com");
        let faceDown;
        let faceUp;
        let mainDeck;
        let totalDiscard;
        let available;

        switch (game.currentPhase) {
            case "waitingForPlayerChoosing":
                if(!this.state.choseCards){
                    let characterDeck = game.characterDeck.available;
                    randNum = Math.floor(Math.random()*characterDeck.length);
                    // let card;
                    // if(characterDeck.includes("assassin")){
                    //     card = "assassin";
                    // }else{
                    //     card = characterDeck[randNum];
                    // }
                    let card = characterDeck[randNum];
                    let fullCard = characterCards.find(cCard=>cCard.cardName === card);
                    setTimeout(() => {
                        this.chooseCharacterCard(fullCard, game);
                    }, (randNum*1000)+5000); 
                }
            break;
            case "waitingForPlayerDiscarding":
                if(!this.state.choseCards){
                    let characterDeck = game.characterDeck.available;
                    randNum = Math.floor(Math.random()*characterDeck.length);
                    // let card;
                    // if(characterDeck.includes("assassin")){
                    //     card = "assassin";
                    // }else{
                    //     card = characterDeck[randNum];
                    // }
                    let card = characterDeck[randNum];
                    let fullCard = characterCards.find(cCard=>cCard.cardName === card);
                    setTimeout(() => {
                        this.discardCharacterCard(fullCard, game);
                    }, (randNum*1000)+5000); 
                }
            break;
            case "waitingForPlayerAction":
                if(!this.state.usedAction && !game.usedAction){
                    if(currPlayer.currentCharacter != "architect" && currPlayer.currentCharacter != "magician" && currPlayer.hand.length<2 || currPlayer.gold>5){
                        randNum = Math.floor(Math.random()*3);
                        setTimeout(() => {
                            this.actionDrawCard();
                        }, (randNum*1000) + 3000);
                        
                    }else {
                        randNum = Math.floor(Math.random()*3);
                        setTimeout(() => {
                            this.actionGetGold();
                        }, (randNum*1000) + 3000);
    
                    }
                }
     
            break;
            case "waitingForCardDraw":
                
                randNum = Math.floor(Math.random()*game.districtChoice.length);
                let districtCard = game.districtChoice[randNum];
                setTimeout(() => {
                    this.drawDistrictCard(districtCard);
                }, (randNum*2000)+3000);
                
            
            break;
            case "stealing":
                if(!this.state.usedPower && !game.usedPower){
                    faceDown = game.characterDeck.faceDown;
                    faceUp = game.characterDeck.faceUp;
                    mainDeck = game.characterDeck.main;
                    totalDiscard = faceDown.concat(faceUp);
                    available = mainDeck.filter(card=>{return (!totalDiscard.includes(card) && card!="thief" && card!="assassin" && card!=currPlayer.character1 && card!=currPlayer.character2 && card!=currPlayer.discard1 && card!=currPlayer.discard2 && card!=game.currentDead)});
                    
                    randNum = Math.floor(Math.random()*available.length);
                    let stealingFrom = available[randNum];
                    setTimeout(() => {
                        this.stealFromPlayer(stealingFrom);
                    }, (randNum*2000)+3000);
                }
            
            break;
            case "assassinating":
                if(!this.state.usedPower && !game.usedPower){
                    faceDown = game.characterDeck.faceDown;
                    faceUp = game.characterDeck.faceUp;
                    mainDeck = game.characterDeck.main;
                    totalDiscard = faceDown.concat(faceUp);
                    available = mainDeck.filter(card=>{return (!totalDiscard.includes(card) && card!="assassin" && card!=currPlayer.character1 && card!=currPlayer.character2 && card!=currPlayer.discard1 && card!=currPlayer.discard2)});
                    
                    randNum = Math.floor(Math.random()*available.length);
                    let assassinating = available[randNum];
                    setTimeout(() => {
                        this.assassinatePlayer(assassinating);
                    }, (randNum*2000)+3000);
                }
            break;
            case "buildingPhase":
                let hand = currPlayer.hand;
                let board = currPlayer.board;
                
                let yellow1 = currPlayer.board.yellow;
                let blue1 = currPlayer.board.blue;
                let green1 = currPlayer.board.green;
                let red1 = currPlayer.board.red;
                let purple1 = currPlayer.board.purple;

                let totalBoard = yellow1.concat(blue1).concat(green1).concat(red1).concat(purple1);
                
                let affordable = [];
                hand.map(card=>{
                    let fullCard = districtCards.find(dCard=>dCard.cardName === card);
                    if(fullCard.cost<=currPlayer.gold && !totalBoard.includes(fullCard.cardName)){
                        affordable.push(fullCard);
                    }
                })
                affordable.sort((a,b)=>b.cost-a.cost);
                
                let canBuild = affordable.length>0 ? true : false;


                switch (currPlayer.currentCharacter) {
                    case "assassin":
                        if(canBuild && (game.districtsBuilt===0)){
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                for(let i = 0; i<affordable.length; i++){
                                    if(!totalBoard.includes(affordable[i].cardName)){
                                        this.buildDistrict(affordable[i]);
                                        break;
                                    }
                                }
                                this.setState({builtDistrict:true});

                            }, (randNum*2000)+3000);
                        }else{
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+3000);
                        }
                        break;
                    case "thief":
                        if(canBuild && (game.districtsBuilt===0)){
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                for(let i = 0; i<affordable.length; i++){
                                    if(!totalBoard.includes(affordable[i].cardName)){
                                        this.buildDistrict(affordable[i]);
                                        break;
                                    }
                                }
                                this.setState({builtDistrict:true});

                            }, (randNum*2000)+3000);
                        }else{
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+3000);
                        }
                        break;
                    case "magician":
                        if(canBuild && (game.districtsBuilt===0)){
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                for(let i = 0; i<affordable.length; i++){
                                    if(!totalBoard.includes(affordable[i].cardName)){
                                        this.buildDistrict(affordable[i]);
                                        break;
                                    }
                                }
                                this.setState({builtDistrict:true});

                            }, (randNum*2000)+3000);
                        }else if (!game.usedPower && !this.state.usedPower && opponentPlayer.hand.length>hand.length){
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                this.tradeHandWithPlayer(opponentPlayer);
                            }, (randNum*2000)+3000);
                        }else{
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+3000);
                        }
                        break;
                    case "king":
                        randNum = Math.floor(Math.random()*3);
                        
                        
                        if(canBuild && (game.districtsBuilt===0)){
                            if(affordable.includes("palace") && !board.yellow.includes("palace")){
                                setTimeout(() => {
                                    this.buildDistrict("palace");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("castle") && !board.yellow.includes("castle")){
                                setTimeout(() => {
                                    this.buildDistrict("castle");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("manor") && !board.yellow.includes("manor")){
                                setTimeout(() => {
                                    this.buildDistrict("manor");
                                }, (randNum*2000)+3000);
                            }else{
                                
                                this.setState({builtDistrict:true});
                                setTimeout(() => {
                                    for(let i = 0; i<affordable.length; i++){
                                        if(!totalBoard.includes(affordable[i].cardName)){
                                            this.buildDistrict(affordable[i]);
                                            break;
                                        }
                                    }

                                }, (randNum*2000)+3000);
                            }
                        } else if (!game.usedPower && !this.state.usedPower && board.yellow.length>0){
                            
                            setTimeout(() => {
                                this.usePower("king");
                            }, (randNum*2000)+3000);
                        } else{
                            
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+3000);
                        }

                        break;
                    case "bishop":
                        randNum = Math.floor(Math.random()*3);
                        if(canBuild && (game.districtsBuilt===0)){
                            if(affordable.includes("cathedral") && !board.blue.includes("cathedral")){
                                setTimeout(() => {
                                    this.buildDistrict("cathedral");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("monastery") && !board.blue.includes("monastery")){
                                setTimeout(() => {
                                    this.buildDistrict("monastery");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("church") && !board.blue.includes("church")){
                                setTimeout(() => {
                                    this.buildDistrict("church");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("temple") && !board.blue.includes("temple")){
                                setTimeout(() => {
                                    this.buildDistrict("temple");
                                }, (randNum*2000)+3000);
                            }else{
                                
                                setTimeout(() => {
                                    for(let i = 0; i<affordable.length; i++){
                                        if(!totalBoard.includes(affordable[i].cardName)){
                                            this.buildDistrict(affordable[i]);
                                            break;
                                        }
                                    }
                                    this.setState({builtDistrict:true});

                                }, (randNum*2000)+3000);
                            }
                        } else if (!game.usedPower && !this.state.usedPower && board.blue.length>0){
                            
                            setTimeout(() => {
                                this.usePower("bishop");
                            }, (randNum*2000)+3000);
                        } else{
                            
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+3000);
                        }
                        break;
                    case "merchant":
                        randNum = Math.floor(Math.random()*3);
                        if(canBuild && (game.districtsBuilt===0)){
                            if(affordable.includes("townHall") && !board.green.includes("townHall")){
                                setTimeout(() => {
                                    this.buildDistrict("townHall");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("harbor") && !board.green.includes("harbor")){
                                setTimeout(() => {
                                    this.buildDistrict("harbor");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("docks") && !board.green.includes("docks")){
                                setTimeout(() => {
                                    this.buildDistrict("docks");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("tradingPost") && !board.green.includes("tradingPost")){
                                setTimeout(() => {
                                    this.buildDistrict("tradingPost");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("market") && !board.green.includes("market")){
                                setTimeout(() => {
                                    this.buildDistrict("market");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("tavern") && !board.green.includes("tavern")){
                                setTimeout(() => {
                                    this.buildDistrict("tavern");
                                }, (randNum*2000)+3000);
                            }else{
                                
                                setTimeout(() => {
                                    for(let i = 0; i<affordable.length; i++){
                                        if(!totalBoard.includes(affordable[i].cardName)){
                                            this.buildDistrict(affordable[i]);
                                            break;
                                        }
                                    }

                                }, (randNum*2000)+3000);
                            }
                        } else if (!game.usedPower && !this.state.usedPower && board.green.length>0){
                            
                            setTimeout(() => {
                                this.usePower("merchant");
                            }, (randNum*2000)+3000);
                        } else{
                            
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+3000);
                        }
                        break;
                    case "architect":
                        if(canBuild && game.districtsBuilt<3){
                            
                            randNum = Math.floor(Math.random()*3);
                            setTimeout(() => {
                                for(let i = 0; i<affordable.length; i++){
                                    if(!totalBoard.includes(affordable[i].cardName)){
                                        this.buildDistrict(affordable[i]);
                                        break;
                                    }
                                }
                                this.setState({builtDistrict:true});

                            }, (randNum*2000)+3000);
                        }else{
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+6000);
                        }
                        break;
                    case "warlord":
                        randNum = Math.floor(Math.random()*3);
                        if(canBuild && (game.districtsBuilt===0)){
                            if(affordable.includes("fortress") && !board.red.includes("fortress")){
                                setTimeout(() => {
                                    this.buildDistrict("fortress");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("battlefield") && !board.red.includes("battlefield")){
                                setTimeout(() => {
                                    this.buildDistrict("battlefield");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("prison") && !board.red.includes("prison")){
                                setTimeout(() => {
                                    this.buildDistrict("prison");
                                }, (randNum*2000)+3000);
                            }else if (affordable.includes("watchtower") && !board.red.includes("watchtower")){
                                setTimeout(() => {
                                    this.buildDistrict("watchtower");
                                }, (randNum*2000)+3000);
                            }else{
                                
                                setTimeout(() => {
                                    for(let i = 0; i<affordable.length; i++){
                                        if(!totalBoard.includes(affordable[i].cardName)){
                                            this.buildDistrict(affordable[i]);
                                            break;
                                        }
                                    }
                                    this.setState({builtDistrict:true});
                                }, (randNum*2000)+3000);
                            }
                        } else if (!game.usedPower && !this.state.usedPower && board.red.length>0){
                            
                            setTimeout(() => {
                                this.usePower("warlord");
                            }, (randNum*2000)+3000);
                        } else if (opponentPlayer.currentCharacter!="bishop" && opponentPlayer.character1!="bishop" && opponentPlayer.character2!="bishop" && !this.state.destroyComplete && !game.destroyedDistrict){
                            
                            let yellow2 = opponentPlayer.board.yellow;
                            let blue2 = opponentPlayer.board.blue;
                            let green2 = opponentPlayer.board.green;
                            let red2 = opponentPlayer.board.red;
                            let purple2 = opponentPlayer.board.purple;

                            let totalOpponentBoard = yellow2.concat(blue2).concat(green2).concat(red2).concat(purple2);
                            
                            let affordable2 = [];
                            totalOpponentBoard.map(card=>{
                                let fullCard = districtCards.find(dCard=>dCard.cardName === card);
                                if(totalOpponentBoard.includes("greatWall")){
                                    if((fullCard.cost)<=currPlayer.gold && fullCard.cardName!="keep"){
                                        affordable2.push(fullCard);
                                    }
                                }else{
                                    if((fullCard.cost-1)<=currPlayer.gold && fullCard.cardName!="keep"){
                                        affordable2.push(fullCard);
                                    }
                                }         
                            })
                            affordable2.sort((a,b)=>b.cost-a.cost);
                            
                            let canDestroy = affordable2.length>0 ? true : false;
                            this.setState({destroyComplete:true});

                            if(canDestroy){
                                setTimeout(() => {
                                    this.destroyCard(affordable2[0]);
                                }, (randNum*2000)+3000);
                            }
                            
                        } else{
                            
                            setTimeout(() => {
                                this.nextTurn();
                            }, (randNum*2000)+3000);
                        }
                        break;               
                    default:
                        break;
                }
            break;
            default:
                break;
        }

    }


    getGame = () => {
        axios.get(`db/games?gameId=${this.state.currentUser.currentGame}&idToken=${this.verifyToken()}`)
            .then((response)=>{
                let game = response.data[0];
                let d = new Date();
                // console.log("gettime", d.getTime());
                // console.log("parse", game.timeStamp);
                if(game){
                    // if(game.gameStarted || d.getTime() - game.timeStamp < 500000){
                        if(game.useBot){
                            this.setState({refresh:true});
                        }
                        this.setGameState(game);  
    
                        this.getMessages(game);
                    // }else{
                    //     this.deleteGame();
                    // }
                    
                }                
             


                // axios.get(`db/gameLogs?gameId=${this.state.currentUser.currentGame}&idToken=${this.verifyToken()}`)
                //     .then((res)=>{
                //         let actionLog = res.data[0];
                //         console.log(actionLog);
                //         this.setState({actionLog});
                //         this.setGameState(game);  
                //     }).catch(err=>{
                //         console.log(err);
                //     })
                   
            }).catch(err=>{
                console.log(err);
            })
    }

    joinGame = (newUser, lobbyGameId) => {
        this.setState({leftGame:false, messages: []});
        
        let currentUser = newUser ? newUser : this.state.currentUser;
        if(currentUser && currentUser.email!=null){
            let gameId = newUser ? this.state.game.gameId : this.state.gameId;
            gameId = lobbyGameId ? lobbyGameId : gameId;
            this.setState({gameId});
            let game = {
                currentUser,
                gameId,
                idToken: this.verifyToken(),
                joiningGame: true
            }
            
            axios.post('db/games', game)
                .then((response)=>{
                    if(response.data == "Invalid Game Code"){
                        this.setState({error: "Invalid Game Code / Game Does Not Exist Anymore"});
                        this.removeChallenge(gameId);
                        setTimeout(()=>{
                            this.setState({error: ""});
                        },3000)
                    }else if (response.data == "Game Already Started"){
                        this.setState({error: "Game Already Started"});
                        this.removeChallenge(gameId);
                        setTimeout(()=>{
                            this.setState({error: ""});
                        },3000)
                    }else if(response.data == "Game Full"){
                        this.setState({error: "Game Full"});
                        this.removeChallenge(gameId);
                        setTimeout(()=>{
                            this.setState({error: ""});
                        },3000)
                    }else if(response.data == "Already In Game"){
                        this.setState({error: "You Are Already In This Game"});
                        this.removeChallenge(gameId);
                        setTimeout(()=>{
                            this.setState({error: ""});
                        },3000)
                    }
                    else{
                        let game = response.data;
                        if(game.length != 0){
                            socket.emit("playerJoin", {game,newUser});
                            currentUser.currentGame = game.gameId;
                            if(currentUser.email!="bot@botmail.com"){
                                socket.emit("addToOnlineList", currentUser);
                                axios.put(`db/onlineList/add`, {user: currentUser, idToken: this.verifyToken()})
                                .then((response)=>{
                                    
                                }).catch(err=>{
                                    console.log(err);
                                })
                            }
                           
                            this.setState({inLobby:false, showGameInvites:false});
                            // socket.emit("foundMatch", {newUser, game});
                        }
                    }
                    
                }).catch(err=>{
                    console.log(err);
                })
        }else{
            this.setState({error: "Cannot join game, there is a problem with your account, please try making a new account"});
        }
        

            
    }

    startGame = () => {
        // this.setState({gameStarted:true});
        let game = Object.assign({}, this.state.game);
        let districtDeck = game.districtDeck;
        let players = game.players;
        let index = 0; 
        let deckSize = districtDeck.length;
        let districtCard;
        let playerHand = [];
        let numberOfPlayers = players.length;

        // let firstPlayer = Math.floor(Math.random()*numberOfPlayers);

        // let temp = players.slice(firstPlayer);
        // players.splice(firstPlayer);
        // players = temp.concat(players);

        // console.log(players);

        players.map((player, playerIndex)=>{
            for(let i = 0; i<4; i++){
                index = Math.floor(Math.random()*deckSize);
                districtCard = districtDeck[index];
                districtDeck.splice(index,1);
                playerHand.push(districtCard);
                deckSize--;
            }
            players[playerIndex].hand = playerHand;
            players[playerIndex].gold = 2;
            players[playerIndex].board = {yellow:[], blue:[],green:[], red:[],purple:[]};
            players[playerIndex].showChar1 = false;
            players[playerIndex].showChar2 = false;
            players[playerIndex].turnOrder = playerIndex;
            playerHand = [];
        
        })

        let useTimer = game.useBot || game.useMatchmaking ? true : this.state.useTimer;
        useTimer = game.rated ? true : useTimer;

        let newGame = {
            turnCount: 0,
            currentTurn: players[0].username,
            currentKing: players[0].email,
            currentKingName: players[0].username,
            showCurrentGame: true,
            currentPlayerEmail: players[0].email, 
            currentPhase: "setup",
            gameStarted: true,
            numberOfPlayers,
            currentUser: this.state.currentUser,
            points: 0,
            idToken: this.verifyToken(),
            useTimer
        }

        game = Object.assign({}, game, newGame)
          
        axios.post('db/games', game)
            .then((response)=>{
                game = response.data;
                socket.emit("startGame", game);
                this.setState({playerIndex:0, useBot:false, useMatchmaking:false, inLobby:false});
            }).catch(err=>{
                console.log(err);
            })
    }

    handleChange = (name, value) => {
        this.setState({[name]: value });
        if(name=="rated" && value == true){
            this.setState({numberOfPlayers: 2, dropDownLocked: true, useTimer:true});
        }else if (name=="rated" && value == false){
            this.setState({dropDownLocked: false, useTimer:false});
        }
    }

    handleToggleChange = () => {
        this.setState({useTimer: !this.state.useTimer});
    }

    handleKeyPress = (evt) => {
        let currMsg = this.state.currentChatMessage.replace(/\s/g, '');
        if(evt.key === 'Enter' && currMsg.length > 0){
            document.getElementById('chatInput').value = "";
            
            let message = this.state.currentChatMessage;
            let name = this.state.currentUser.username;
            let gameId = this.state.game.gameId;

            let body = {gameId, name, message, idToken: this.verifyToken()};
            let data = {gameId, name, message};

            this.setState({currentMessage:""});

            axios.put('db/chats', body)
            .then((response)=>{
                socket.emit("sendMessage", data);
            }).catch(err=>{
                console.log(err);
            })

        }
    }

    getMessages = (game) => {
        axios.get(`db/chats?idToken=${this.verifyToken()}&gameId=${game.gameId}`)
        .then((response)=>{
            let messages = response.data[0].messages;
            this.setState({messages, messageCounter: messages.length});
            
        }).catch(err=>{
            console.log(err);
        })
    }

    toggleCharacterCards = () => {
        this.setState({viewDiscardedCards: !this.state.viewDiscardedCards});
    }

    openCharacterCardMenu = (currentCharacterCard) => {
        this.setState({currentCharacterCard});
    }

    openDistrictCardMenu = (currentDistrictCard) => {
        this.setState({currentDistrictCard});
    }

    openHandDistrictCardMenu = (currentHandDistrictCard) => {
        this.setState({currentHandDistrictCard});
    }

    actionGetGold = async () => {
        this.setState({usedAction:true});
        let game = Object.assign({}, this.state.game);
        let currentPlayerEmail = game.currentPlayerEmail === "bot@botmail.com" ? "bot@botmail.com" : this.state.currentUser.email;
        let currPlayer = game.players.find(cPlayer => cPlayer.email === currentPlayerEmail);

        currPlayer.gold += 2;

        game.usedAction = true;

        this.setState(game);

        await this.newAction(`<strong>${this.state.game.currentTurn}</strong> collected 2 gold`);
        
        this.characterAction(game, currPlayer);
      
    }

    actionDrawCard = () => {
        this.setState({usedAction:true});
        let game = Object.assign({}, this.state.game);
        let currentPlayerEmail = game.currentPlayerEmail === "bot@botmail.com" ? "bot@botmail.com" : this.state.currentUser.email;
        let currPlayer = game.players.find(cPlayer => cPlayer.email === currentPlayerEmail);

        if(currPlayer.board.purple.includes("library")){
            this.draw2Cards("library");
        }else{
            let districtDeck = game.districtDeck;
            let deckSize = districtDeck.length;
            let districtChoice = [];
            let currNum;
            let numOfCards = 2;

            if(currPlayer.board.purple.includes("observatory")){
                numOfCards++;
            }
            
            for(let i = 0; i<numOfCards; i++){
                let currDistrictIndex = Math.floor(Math.random()*deckSize);
                while(currDistrictIndex === currNum){
                    currDistrictIndex = Math.floor(Math.random()*deckSize);
                }
                let currDistrictCard = districtDeck[currDistrictIndex];
                // districtDeck.splice(index,1);
                districtChoice.push(currDistrictCard);
                deckSize--;
                currNum = currDistrictIndex;
            }

            game.districtChoice = districtChoice;
            game.currentPhase = "waitingForCardDraw";
        

            //this.setState({game});

            this.updateGameState(game);
        }
        
    }

    drawDistrictCard = async (districtCard) => {
        this.setState({drewCard:true});
        let game = Object.assign({}, this.state.game);
        let currentPlayerEmail = game.currentPlayerEmail === "bot@botmail.com" ? "bot@botmail.com" : this.state.currentUser.email;
        let currPlayer = game.players.find(cPlayer => cPlayer.email === currentPlayerEmail);

        let districtDeck = game.districtDeck;
        let index = districtDeck.indexOf(districtCard);
        districtDeck.splice(index,1);
        currPlayer.hand.push(districtCard);

        await this.newAction(`<strong>${this.state.game.currentTurn}</strong> drew a district card`);
        game.usedAction = true;

        this.characterAction(game, currPlayer);

    }

    characterAction = async (game, currPlayer) => {
        if(currPlayer.currentCharacter === game.currentDead && currPlayer.board.purple.includes("hospital")){
            game.currentPhase = "actionPhase";
            await this.newAction(`<strong>${this.state.game.currentTurn}</strong> was shot, and is using <strong>Hospital</strong>`);
            this.updateGameState(game);
        }else{
            game.currentPhase = "buildingPhase";

            if(currPlayer.currentCharacter === "assassin"){
                await this.newAction(`<strong>${this.state.game.currentTurn}</strong> is choosing who to assassinate`);
                game.currentPhase = "assassinating";
                this.updateGameState(game);
            }else if(currPlayer.currentCharacter === "thief"){
                await this.newAction(`<strong>${this.state.game.currentTurn}</strong> is choosing who to steal from`);
                game.currentPhase = "stealing";
                this.updateGameState(game);
            }else if(currPlayer.currentCharacter === "merchant"){
                await this.newAction(`<strong>${this.state.game.currentTurn}</strong> received 1 extra gold as merchant`);
                currPlayer.gold++;
                this.updateGameState(game); 
            }else if(currPlayer.currentCharacter === "architect"){
                await this.newAction(`<strong>${this.state.game.currentTurn}</strong> got 2 extra district cards and can build up to 3 this turn`);
                this.draw2Cards();
            }else if(currPlayer.currentCharacter === "warlord"){
                this.updateGameState(game);
            }else{
                this.updateGameState(game);
            }
        }

    }

    buildDistrict = async (districtCard) => {

        let game = Object.assign({}, this.state.game);
        let currentPlayer = Object.assign({},this.state.currentPlayer);

        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);

        if(currPlayer.currentCharacter==="architect" && game.districtsBuilt<=2){
            this.setState({builtDistrict:false});
        }else{
            this.setState({builtDistrict:true});
        }

        switch (districtCard.color) {
            case "yellow":
                if(currPlayer.board.yellow.includes(districtCard.cardName)){
                    let currentAction = `<strong>You CANNOT have more than 1 <u>${districtCard.cardName}</u> in your city!</strong>`
                    this.setState({currentAction, builtDistrict:false});
                }else{
                    currPlayer.board.yellow.push(districtCard.cardName);
                    currPlayer.hand.splice(currPlayer.hand.indexOf(districtCard.cardName),1);
                    currPlayer.gold -= districtCard.cost;
                    game.districtsBuilt++;
                }
                break;
            case "blue":
                if(currPlayer.board.blue.includes(districtCard.cardName)){
                    let currentAction = `<strong>You CANNOT have more than 1 <u>${districtCard.cardName}</u> in your city!</strong>`
                    this.setState({currentAction, builtDistrict:false});
                }else{
                    currPlayer.board.blue.push(districtCard.cardName);
                    currPlayer.hand.splice(currPlayer.hand.indexOf(districtCard.cardName),1);
                    currPlayer.gold -= districtCard.cost;
                    game.districtsBuilt++;
                }
                break;
            case "green":
                if(currPlayer.board.green.includes(districtCard.cardName)){
                    let currentAction = `<strong>You CANNOT have more than 1 <u>${districtCard.cardName}</u> in your city!</strong>`
                    this.setState({currentAction, builtDistrict:false});
                }else{
                    currPlayer.board.green.push(districtCard.cardName);
                    currPlayer.hand.splice(currPlayer.hand.indexOf(districtCard.cardName),1);
                    currPlayer.gold -= districtCard.cost;
                    game.districtsBuilt++;
                }
                break;
            case "red":
                if(currPlayer.board.red.includes(districtCard.cardName)){
                    let currentAction = `<strong>You CANNOT have more than 1 <u>${districtCard.cardName}</u> in your city!</strong>`
                    this.setState({currentAction, builtDistrict:false});
                }else{
                    currPlayer.board.red.push(districtCard.cardName);
                    currPlayer.hand.splice(currPlayer.hand.indexOf(districtCard.cardName),1);
                    currPlayer.gold -= districtCard.cost;
                    game.districtsBuilt++;
                }
                break;
            case "purple":
                if(currPlayer.board.purple.includes(districtCard.cardName)){
                    let currentAction = `<strong>You CANNOT have more than 1 <u>${districtCard.cardName}</u> in your city!</strong>`
                    this.setState({currentAction, builtDistrict:false});
                }else{
                    currPlayer.board.purple.push(districtCard.cardName);
                    currPlayer.hand.splice(currPlayer.hand.indexOf(districtCard.cardName),1);
                    if(currPlayer.board.purple.includes("factory") && districtCard.cardName != "factory"){
                        currPlayer.gold -= (districtCard.cost - 1);
                    }else{
                        currPlayer.gold -= districtCard.cost;
                    }
                    game.districtsBuilt++;
                }
                break;
        }

        switch (districtCard.cardName) {
            case "bellTower":
                game.districtsToWin--;
                break;

            default:
                break;
        }

        let maxDistrictsBuilt = currPlayer.board.yellow.length + currPlayer.board.blue.length + currPlayer.board.green.length + currPlayer.board.red.length + currPlayer.board.purple.length;
        if(maxDistrictsBuilt>=game.maxDistrictsBuilt){
            game.maxDistrictsBuilt = maxDistrictsBuilt;
        }

        if(game.firstToComplete === "" && game.maxDistrictsBuilt>=game.districtsToWin){
            game.gameEnding = true;
            game.firstToComplete = game.currentPlayerEmail;
            if(game.currentPlayerEmail === game.firstToComplete){
                await this.newAction(`<strong>${game.currentTurn}</strong> has built 8 districts! The game will end this round.`);
            }
        }
        // this.setState({game});



        this.updateGameState(game);
    }

    showCardInfo = (currentCharacterInfo) => {
        this.setState({currentCharacterInfo, showCardInfo:true});
    }

    closeInfoBox = () => {
        this.setState({showCardInfo:false});
    }

    chooseCharacterCard = (characterCard, currGame) => {
        let game = currGame ? currGame : Object.assign({}, this.state.game);
        if(game.numberOfPlayers>2 || (game.numberOfPlayers === 2 && game.draftStage === 1 && game.turnCount === 1)){
            this.setState({choseCards:true});
        }
        game.characterDeck.available.splice(game.characterDeck.available.indexOf(characterCard.cardName),1);
        let currentPlayerEmail = game.currentPlayerEmail === "bot@botmail.com" ? "bot@botmail.com" : this.state.currentUser.email;
        let currPlayer = game.players.find(cPlayer => cPlayer.email === currentPlayerEmail);
        if(game.draftStage === 0){
            currPlayer.character1 = characterCard.cardName;
            currPlayer.currNumber1 = characterCard.number;
        }else{
            if(characterCard.number<currPlayer.currNumber1){
                let temp1 = currPlayer.character1;
                let temp2 = currPlayer.currNumber1;
                currPlayer.character1 = characterCard.cardName;
                currPlayer.currNumber1 = characterCard.number;
                currPlayer.character2 = temp1;
                currPlayer.currNumber2 = temp2;

            }else{
                currPlayer.character2 = characterCard.cardName;
                currPlayer.currNumber2 = characterCard.number;
            }
        }
        let currentKing = "";
        if(characterCard.cardName==="king"){
            currentKing = currPlayer.email;            
        }

        if(game.draftStage === 1 && game.numberOfPlayers === 7 && game.turnCount === 5){
            game.characterDeck.available.push(game.characterDeck.faceDown[0]);
            game.characterDeck.faceDown = [];
        }

        if(game.draftStage === 1 && game.numberOfPlayers === 2 && game.turnCount === 1){
            game.turnCount++;
        }

        if(game.numberOfPlayers>2){
            currPlayer.currentCharacter = characterCard.cardName;
            currPlayer.currNumber = characterCard.number;
        }
        
        this.setState({game});

        this.nextTurn(game,currentKing);

    }

    discardCharacterCard = (characterCard, currGame) => {
        this.setState({choseCards:true});
        let game = currGame ? currGame : Object.assign({}, this.state.game);
        game.characterDeck.available.splice(game.characterDeck.available.indexOf(characterCard.cardName),1);
        
        let currentPlayerEmail = game.currentPlayerEmail === "bot@botmail.com" ? "bot@botmail.com" : this.state.currentUser.email;
        let currPlayer = game.players.find(cPlayer => cPlayer.email === currentPlayerEmail);

        if(game.draftStage === 0){
            currPlayer.discard1 = characterCard.cardName;
        }else{
            currPlayer.discard2 = characterCard.cardName;
        }
        
        if(game.draftStage === 1 && game.turnCount === 0){
            game.characterDeck.available.push(game.characterDeck.faceDown[0]);
            game.characterDeck.faceDown = [];
        }

        this.setState({game});

        this.nextTurn(game);
    }


    usePower = (character, action) => {

        let game = Object.assign({}, this.state.game);

        let currentPlayer = Object.assign({},this.state.currentPlayer);

        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);
        
        switch (character) {
            case "magician":
                this.magicianPower(game,currPlayer);
                break;
            case "king":
                this.kingPower(game,currPlayer);
                break;
            case "bishop":
                this.bishopPower(game,currPlayer);
            break;
            case "merchant":
                this.merchantPower(game,currPlayer);
            break;
            case "architect":
                this.architectPower();
            break;
            case "warlord":
                this.warlordPower(game,currPlayer, action);
            break;
        }
    }

    magicianPower = (game, currPlayer) => {
        this.setState({tradedCards:true});

        game.currentPhase = "trading";
        this.updateGameState(game);
    }

    kingPower = async (game,currPlayer) => {

        this.setState({earnedGold:true});
        let goldEarned = 0;
        if(currPlayer.board.purple.includes("magicSchool")){
            goldEarned = (currPlayer.board.yellow.length+1);
        }else{
            goldEarned = currPlayer.board.yellow.length;
        }
        currPlayer.gold += goldEarned;
        game.usedPower = true;
        if(goldEarned>0){
            await this.newAction(`<strong>${currPlayer.username}</strong> collected <strong>${goldEarned} gold</strong> from noble districts.`);
        }

        this.updateGameState(game);
    }

    bishopPower = async (game,currPlayer) => {

        this.setState({earnedGold:true});
        let goldEarned = 0;
        if(currPlayer.board.purple.includes("magicSchool")){
            goldEarned = (currPlayer.board.blue.length+1);
        }else{
            goldEarned = currPlayer.board.blue.length;
        }
        currPlayer.gold += goldEarned;
        game.usedPower = true;
        if(goldEarned>0){
            await this.newAction(`<strong>${currPlayer.username}</strong> collected <strong>${goldEarned} gold</strong> from religious districts.`);
        }

        this.updateGameState(game);
    }

    merchantPower = async (game,currPlayer) => {

        this.setState({earnedGold:true});
        let goldEarned = 0;
        if(currPlayer.board.purple.includes("magicSchool")){
            goldEarned = (currPlayer.board.green.length+1);
        }else{
            goldEarned = currPlayer.board.green.length;
        }
        currPlayer.gold += goldEarned;
        game.usedPower = true;
        if(goldEarned>0){
            await this.newAction(`<strong>${currPlayer.username}</strong> collected <strong>${goldEarned} gold</strong> from trade districts.`);
        }

        this.updateGameState(game);
    }

    warlordPower = async (game,currPlayer, action) => {

        if(action === "destroy"){
            this.setState({destroyedDistrict:true});
            game.currentPhase = "destroying";
            this.updateGameState(game);
        }else{
            this.setState({earnedGold:true});
            let goldEarned = 0;
            if(currPlayer.board.purple.includes("magicSchool")){
                goldEarned = (currPlayer.board.red.length+1);
            }else{
                goldEarned = currPlayer.board.red.length;
            }
            currPlayer.gold += goldEarned;
            game.usedPower = true;
            if(goldEarned>0){
                await this.newAction(`<strong>${currPlayer.username}</strong> collected <strong>${goldEarned} gold</strong> from military districts.`);
            }

            this.updateGameState(game);
        }

    }

    nextBoard = (direction) => {
        let playerIndex = this.state.playerIndex;
        if(direction==="right"){
            playerIndex++;
            if(playerIndex>=this.state.game.numberOfPlayers){
                playerIndex = 0;
            }            
        }else{
            playerIndex--;
            if(playerIndex<0){
                playerIndex = this.state.game.numberOfPlayers-1;
            }
        }

        this.setState({playerIndex});
    }

    assassinatePlayer = async(cCard) => {
        this.setState({assassinationComplete:true});
        let game = Object.assign({}, this.state.game);

        // let currentDead = game.players.find(player=>player.currentCharacter === cCard);
        // // alert(currentDead);
        // if(currentDead){
        //     game.currentDead = currentDead.email;
        // }else{
        //     game.currentDead = "";
        // }
        game.currentDead = cCard;

        await this.newAction(`<strong>${this.state.game.currentTurn}</strong> has assassinated ${cCard}!`);
        await this.newAction(`Their turn will be skipped this round`);

        game.currentPhase = "buildingPhase";
        this.updateGameState(game);
    }

    stealFromPlayer = async (cCard) => {
        this.setState({stealingComplete:true});
        let game = Object.assign({}, this.state.game);

        // let currentStolen = game.players.find(player=>player.currentCharacter === cCard);
        // // alert(currentStolen);

        // if(currentStolen){
        //     game.currentStolen = currentStolen.email;
        // }else{
        //     game.currentStolen = "";
        // }
        game.currentStolen = cCard;

        await this.newAction(`<strong>${this.state.game.currentTurn}</strong> is stealing from ${cCard}!`);
        await this.newAction(`At the start of their turn they will give all their gold to <strong>${this.state.game.currentTurn}</strong>`);

        game.currentPhase = "buildingPhase";
        this.updateGameState(game);
    }

    giveGoldToThief = async (game) => {
        let currPlayer = game.players.find(player=>player.email===game.currentPlayerEmail);
        let otherPlayer = game.players.find(player=> player.character1==="thief" || player.character2==="thief");

        otherPlayer.gold += currPlayer.gold;
        currPlayer.gold = 0;

        await this.newAction(`<strong>${currPlayer.username}</strong> gave all their gold to <strong>${otherPlayer.username}</strong>!`);

        game.currentStolen = "";
        // game.currentPhase = "waitingForPlayerAction";
        // this.setState({game});

        this.updateGameState(game);

    }

    tradeHandWithPlayer = async (tradingPlayer) => {
        this.setState({madeTrade: true});
        let game = Object.assign({}, this.state.game);
        let currentPlayer = Object.assign({},this.state.currentPlayer);
        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);
        let otherPlayer = game.players.find(player=>player.email===tradingPlayer.email);

        let temp = currPlayer.hand;
        currPlayer.hand = otherPlayer.hand;
        otherPlayer.hand = temp;

        game.usedPower = true;

        game.currentPhase = "buildingPhase";

        await this.newAction(`<strong>${currPlayer.username}</strong> traded hands with ${otherPlayer.username}`);
        this.updateGameState(game);

    }

    tradeHandWithDeck = () => {
        let game = Object.assign({}, this.state.game);

        game.currentPhase = "tradingWithDeck";
        this.updateGameState(game);
    }

    toggleTradePile = (currCard) => {
        let tradePile = this.state.tradePile || [];

        let temp = [];

        let tradePileCard = tradePile.find(tradePileCard=>tradePileCard.cardIndex===currCard.cardIndex);
        if(tradePileCard){
            tradePile.map((tradeCard)=>{
                if(tradeCard.cardIndex != currCard.cardIndex){
                    temp.push(tradeCard);
                }
            })
            this.setState({tradePile: temp});
        }else{
            tradePile.push(currCard);
            this.setState({tradePile});
        }


        // this.setState({tradePile});
    }

    toggleDiscardPile = () => {
        let game = Object.assign({}, this.state.game);

        if(this.state.discardPileOpen===true && game.usedGraveyard===false){
            if(game.currentPlayerEmail === this.state.currentPlayer.email && game.currentPhase === "waitingForGraveyard"){
                game.currentPhase = "buildingPhase";
            }
        }
        this.setState({discardPileOpen: !this.state.discardPileOpen});
        this.updateGameState(game);
    }

    executeTradeWithDeck = async () => {
        if(this.state.tradePile.length===0){
            this.setState({currentAction:"Select Cards to Trade"});
        }else{
            this.setState({madeTrade: true});

            let game = Object.assign({}, this.state.game);
            let currentPlayer = Object.assign({},this.state.currentPlayer);
            let currPlayer = game.players.find(player=>player.email===currentPlayer.email);

            let pileLength = this.state.tradePile.length;

            let districtDeck = game.districtDeck;

            let tradePile = this.state.tradePile;
            let temp = [];

            tradePile.map(tradeCard=>{
                temp.push(tradeCard.card);
            })

            districtDeck = districtDeck.concat(temp);

            temp.map((pileCard)=>{
                currPlayer.hand.splice(currPlayer.hand.indexOf(pileCard), 1);
            })

            let index;
            let deckSize = districtDeck.length;
            let districtCard;
            let playerHand = [];

            for(let i = 0; i<this.state.tradePile.length; i++){
                index = Math.floor(Math.random()*deckSize);
                districtCard = districtDeck[index];
                districtDeck.splice(index,1);
                playerHand.push(districtCard);
                deckSize--;
            }
            currPlayer.hand = currPlayer.hand.concat(playerHand);

            game.usedPower = true;

            game.currentPhase = "buildingPhase";

            this.setState({tradePile:[]});

            await this.newAction(`<strong>${currPlayer.username}</strong> traded ${pileLength} cards with the deck`);

            this.updateGameState(game);
        }

    }

    draw2Cards = async (special) => {
        let game = Object.assign({}, this.state.game);
        let currentPlayer = Object.assign({},this.state.currentPlayer);
        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);

        let districtDeck = game.districtDeck;

        let index;
        let deckSize = districtDeck.length;
        let districtCard;
        let playerHand = [];

        let cardsToDraw = 2;

        if(special==="smithy"){
            cardsToDraw = 3;
            currPlayer.gold-=2;
            game.usedSmithy = true;
            await this.newAction(`<strong>${game.currentTurn}</strong> used <strong>Smithy</strong>`);
            this.setState({usedSmithy:true});
        }

        for(let i = 0; i<cardsToDraw; i++){
            index = Math.floor(Math.random()*deckSize);
            districtCard = districtDeck[index];
            districtDeck.splice(index,1);
            playerHand.push(districtCard);
            deckSize--;
        }

        currPlayer.hand = currPlayer.hand.concat(playerHand);

        if(special === "park"){
            // this.setState({game});
            this.nextTurn(game);
        }else if(special === "library"){
            await this.newAction(`<strong>${game.currentTurn}</strong> drew 2 district cards with <strong>Library</strong>`);
            this.characterAction(game, currPlayer);
        }
        else{
            game.currentPhase = "buildingPhase";

            this.updateGameState(game);
        }
        

    }

    destroyCard = async(card) => {
        this.setState({destroyingDistrict:true});
        let game = Object.assign({}, this.state.game);
        let currentPlayer = Object.assign({},this.state.currentPlayer);
        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);

        let color = card.color;
        let opponentPlayer =  currPlayer.email === "bot@botmail.com" ? this.state.currentHumanPlayer : game.players[this.state.playerIndex];
        let board =  opponentPlayer.board[color];

        board.splice(board.indexOf(card.cardName),1)
        game.discardPile.push(card.cardName);

        if(opponentPlayer.board.purple.includes("greatWall")){
            currPlayer.gold -= card.cost;
        }else{
            currPlayer.gold -= card.cost-1;
        }

        game.districtsDestroyed++;

        if(card.CardName === "bellTower"){
            game.districtsToWin++;
        }

        await this.newAction(`<strong>${currPlayer.username}</strong> destroyed <strong>${opponentPlayer.username}</strong>'s <strong>${card.cardName}</strong>!`);

        game.currentPhase = "buildingPhase";
        this.updateGameState(game);
    }

    useEffect = (districtCard) => {
        let game = Object.assign({}, this.state.game);
        let currentPlayer = Object.assign({},this.state.currentPlayer);
        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);
        switch (districtCard.cardName) {
            case "hauntedCity":
                this.hauntedCityPower(game, currPlayer);
                break;
            case "graveyard":
                this.graveyardPower(game, currPlayer);
                break;
            case "laboratory":
                this.laboratoryPower(game, currPlayer);
                break;
            case "smithy":
                this.smithyPower();
                break;
            default:
                break;
        }
    }

    hauntedCityPower = (game, currPlayer) => {
        game.currentPhase = "waitingForHauntedCity";
        this.updateGameState(game);
    }

    changeCardColor = async (color) => {
        let game = Object.assign({}, this.state.game);
        game.hauntedCityColor = color;
        await this.newAction(`<strong>${this.state.currentPlayer.username}</strong> changed <strong>Haunted City's</strong> color to <strong>${color}</strong>`);
        game.currentPhase = "buildingPhase";
        this.updateGameState(game);
    }

    graveyardPower = (game, currPlayer) => {
        if(game.discardPile.length === 0){
            let currentAction = `<strong>CANNOT USE</strong>, discard pile empty`;
            this.setState({currentAction})
        }else{
            game.currentPhase = "waitingForGraveyard";
            this.toggleDiscardPile();
            this.updateGameState(game);
        }
      
    }

    getCardFromGraveyard = async (card) => {
        let game = Object.assign({}, this.state.game);
        let currentPlayer = Object.assign({},this.state.currentPlayer);
        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);

        currPlayer.gold-=1;

        let cardChoice = game.discardPile.find(currCard=>currCard === card);

        let index = game.discardPile.indexOf(cardChoice);

        game.discardPile.splice(index,1);

        currPlayer.hand.push(card);

        game.currentPhase = "buildingPhase";
        game.usedGraveyard = true;

        this.setState({discardPileOpen:false, usedGraveyard:true});

        await this.newAction(`<strong>${this.state.game.currentTurn}</strong> used <strong> Graveyard </strong>`);

        this.updateGameState(game);

    }

    laboratoryPower = (game, currPlayer) => {
        game.currentPhase = "waitingForLaboratory";
        this.updateGameState(game);
    }

    tradeCardForGold = async (card) => {
        let game = Object.assign({}, this.state.game);
        let currentPlayer = Object.assign({},this.state.currentPlayer);
        let currPlayer = game.players.find(player=>player.email===currentPlayer.email);

        let index = currPlayer.hand.indexOf(card);
        currPlayer.hand.splice(index,1);
        currPlayer.gold++;

        game.districtDeck.push(card);

        game.usedLaboratory = true;

        await this.newAction(`<strong>${this.state.game.currentTurn}</strong> used <strong>Laboratory</strong>`);

        game.currentPhase = "buildingPhase";
        this.setState({usedLaboratory:true});

        this.updateGameState(game);
    }

    smithyPower = () => {
        this.draw2Cards("smithy");
    }

    cancelAction = () => {
        let game = Object.assign({}, this.state.game);
        this.setState({tradedCards:false, destroyedDistrict:false});
        game.currentPhase = "buildingPhase";
        game.usedAction = false;
        this.updateGameState(game);
    }


    nextTurn = async (game, currentKing) => {
        clearInterval(timerHandle);
        timerHandle = null;
        
        this.setState({endTurnClicked:true});
        if(!game){
            game = Object.assign({}, this.state.game);
        }

        if(currentKing && currentKing!=""){
            game.currentKing = currentKing;
            let currUser = game.players.find(player=>player.email === currentKing);
            game.currentKingName = currUser.username;
            game.showCurrentKing = false;
        }

        if(this.state.currentPlayer.email === game.currentPlayerEmail || game.currentPlayerEmail === "bot@botmail.com"){

            if(game.currentPhase === "buildingPhase" && game.players[game.turnCount].gold === 0 && game.players[game.turnCount].board.purple.includes("poorHouse")){
                game.players[game.turnCount].gold++;
            }

            if(game.currentPhase === "buildingPhase" && game.players[game.turnCount].hand.length === 0 && game.players[game.turnCount].board.purple.includes("park")){
                this.draw2Cards("park");
            }else{
                let previousPlayer = game.currentPlayerEmail;
                if (game.numberOfPlayers === 2 && game.currentPhase != "waitingForPlayerChoosing" || game.numberOfPlayers >= 3){
                    game.turnCount++;
                }
        
                if(game.numberOfPlayers<=3 && game.currentPhase!="waitingForPlayerChoosing" && game.currentPhase!="waitingForPlayerDiscarding"){
                    if(game.turnNumber < 2*game.numberOfPlayers){
                        game.turnCount = game.twoPlayerOrder[game.turnNumber].turnCount;   
                    }else{
                        game.turnCount = game.numberOfPlayers; 
                    }
                }
            
                if(game.turnCount<game.numberOfPlayers){
                    game.currentTurn = game.players[game.turnCount].username;
                    game.currentPlayerEmail = game.players[game.turnCount].email;    

                    switch (game.currentPhase) {
                        case "waitingForPlayerChoosing":
                            if(game.numberOfPlayers === 2){ 
                                game.currentPhase = "waitingForPlayerDiscarding";
                                await this.newAction(`<strong>${game.currentTurn}</strong> is discarding a character`);
                            }else{
                                game.currentPhase = "waitingForPlayerChoosing";
                                await this.newAction(`<strong>${game.currentTurn}</strong> is choosing a character`);
                            }
                            break;
                        case "waitingForPlayerDiscarding":
                            game.currentPhase = "waitingForPlayerChoosing";
                            await this.newAction(`<strong>${game.currentTurn}</strong> is choosing a character`);
                            break;
                            
                        default:
                            game.turnNumber++;
                            game.currentPhase = "actionPhase";
                            if(game.numberOfPlayers<=3){
                                let currPlayer = game.players.find(cPlayer => cPlayer.email === previousPlayer);
                                currPlayer.turn1Done = true;
                            }
                            break;
                    }
                        
                    game.usedAction = false;
                    game.usedPower = false;
                    game.usedSmithy = false;
                    game.usedLaboratory = false;
                    game.usedGraveyard = false;
                    game.districtsBuilt = 0;
                    game.districtsDestroyed = 0;
                    game.newTurn = true;
                    game.timerStarted = false;

                    this.setState({game, usedAction:false, drewCard: false, earnedGold:false, tradedCards:false, destroyedDistrict:false, builtDistrict:false, assassinationComplete:false, stealingComplete:false, usedLaboratory:false, usedGraveyard:false, usedSmithy: false, timerExceeded:false, timer:"-", choseCards:false, currentCharacterCard:"", madeTrade:false, destroyComplete:false});
                    this.updateGameState(game);
                }else{
    
                    game.turnCount = 0;
                    game.turnNumber = 0;
        
                    switch (game.currentPhase) {
                        case "waitingForPlayerChoosing":
                            if(game.numberOfPlayers <= 3){
                                if(game.numberOfPlayers === 3 && game.draftStage===0){
                                    game.currentTurn = game.players[0].username;
                                    game.currentPlayerEmail = game.players[0].email;
                                    game.currentPhase = "waitingForPlayerChoosing";
                                    game.draftStage = 1;
                                    await this.newAction(`<strong>${game.currentTurn}</strong> is choosing a character`);
                                }else if (game.draftStage===1){
                                    let twoPlayerOrder = [];
                                    for(let i = 0; i<game.numberOfPlayers; i++){
                                        twoPlayerOrder.push({turnCount: i, currNumber: game.players[i].currNumber1});
                                        twoPlayerOrder.push({turnCount: i, currNumber: game.players[i].currNumber2});
                                    }
                                    
                                    twoPlayerOrder.sort((a,b)=>a.currNumber-b.currNumber);
                                    game.twoPlayerOrder = twoPlayerOrder;
                                    game.currentPhase = "actionPhase";
                                    game.turnCount = twoPlayerOrder[0].turnCount;
                                    game.currentTurn = game.players[twoPlayerOrder[0].turnCount].username;
                                    game.currentPlayerEmail = game.players[twoPlayerOrder[0].turnCount].email; 
                                    game.turnNumber++;  
                                    game.draftStage = 0; 
                                }
                                
                            }else{
                                game.currentPhase = "actionPhase";
                                game.players.sort((a,b)=>a.currNumber1-b.currNumber1);  
                                game.currentTurn = game.players[0].username;
                                game.currentPlayerEmail = game.players[0].email;
                            }
                            break;
                        case "waitingForPlayerDiscarding":   
                            if(game.draftStage===0){
                                game.currentPhase = "waitingForPlayerChoosing";
                                game.draftStage = 1;
                            }
                            game.currentTurn = game.players[0].username;
                            game.currentPlayerEmail = game.players[0].email;
                            await this.newAction(`<strong>${game.currentTurn}</strong> is choosing a character`);
                            break;
                        default:
                            let currIndex;

                            game.players.sort((a,b)=>a.turnOrder-b.turnOrder);
                            game.twoPlayerOrder = [];

                            game.players.map((player,index)=>{
                                if(game.currentKing === player.email){
                                    currIndex = index;
                                }
                                player.character1 = "";
                                player.character2 = "";
                            })
                            if(currIndex!=0){
                                let temp = game.players.slice(currIndex);
                                game.players.splice(currIndex);
                                game.players = temp.concat(game.players);
                            }

                            game.currentTurn = game.players[0].username;
                            game.currentPlayerEmail = game.players[0].email;
    
                            game.currentDead = "";
                            game.currentStolen = "";
                            game.showCurrentKing = false;
                            
                            game.currentPhase = "choosingPhase";
                            break;
                    
                    }
    
                    game.usedAction = false;
                    game.usedPower = false;
                    game.usedSmithy = false;
                    game.usedLaboratory = false;
                    game.usedGraveyard = false;
                    game.districtsBuilt = 0;
                    game.districtsDestroyed = 0;
                    game.newTurn = true;
                    game.timerStarted = false;
                        
                    this.setState({game, usedAction:false, drewCard: false, earnedGold:false, tradedCards:false, destroyedDistrict:false, builtDistrict:false, assassinationComplete: false, stealingComplete:false, usedLaboratory:false, usedGraveyard:false, usedSmithy: false, timerExceeded:false, timer:"-", choseCards:false, currentCharacterCard:"", madeTrade:false, destroyComplete:false});
    
                    if(game.gameEnding){
                        this.endGame(game);
                    }else{
                        game.players.map((player,index)=>{
                            player.showChar1 = false;
                            player.showChar2 = false;
                            player.turn1Done = false;
                            if(game.currentPhase!="actionPhase"){
                                player.currentCharacter = "";
                                
                            }
                        })

                        this.updateGameState(game);
                    }
                }
            }

      
        }

      
    }

    setupGame = () => {
        this.setState({creatingGame: true, settingUpGame:true});
    }
    cancelGame = () => {
        this.setState({creatingGame: false, settingUpGame:false, inLobby:false});
    }

    openLeaderboard = () => {
        this.setState({showLeaderboard: true, showProfile:false, showGameInvites: false});
        axios.get(`db/users/all?idToken=${this.verifyToken()}`)
        .then((response)=>{
            let users = response.data;
            let totalPlayers = users.length+200;
            users = users.filter(user=>user.wins>0||user.losses>0||user.draws>0);
            users.sort((a,b)=>b.rating.slice(-1)[0]-a.rating.slice(-1)[0]);
            let top100 = users.slice(0, 100);
            this.setState({leaderboardPlayers: top100, totalPlayers});

        }).catch(err=>{
            console.log(err);
        })
    }

    viewGameInvites = () => {
        this.setState({showGameInvites: true, showLeaderboard:false, showProfile:false})
    }

    closeGameInvites = () => {
        document.title = "Citadels";
        this.setState({showGameInvites:false, newChallenge:false})
    }

    toggleOnlineList = () => {
        this.setState({showOnlineList: !this.state.showOnlineList});
        // axios.get(`db/users/all?idToken=${this.verifyToken()}`)
        // .then((response)=>{
        //     let users = response.data;
        //     let totalPlayers = users.length+200;
        //     users = users.filter(user=>user.wins>0||user.losses>0||user.draws>0);
        //     users.sort((a,b)=>b.rating.slice(-1)[0]-a.rating.slice(-1)[0]);
        //     let top100 = users.slice(0, 100);
        //     this.setState({leaderboardPlayers: top100, totalPlayers});

        // }).catch(err=>{
        //     console.log(err);
        // })
    }

    openProfile = () => {
        this.setState({showProfile:true, showLeaderboard:false, showGameInvites: false});
    }

    viewLobby = () => {
        axios.get(`db/games/all?idToken=${this.verifyToken()}`)
        .then((response)=>{
            let publicGames = response.data;
            let d = new Date();
            publicGames = publicGames.filter((game)=>{return d.getTime() - game.timeStamp < 500000});
            this.setState({inLobby: true, publicGames});

        }).catch(err=>{
            console.log(err);
        })
    }

    createGame = (newUser, playBot) => {
        this.setState({creatingGame: true, settingUpGame:false, messages: []});
        let useBot = playBot ? playBot : false;
        let privateGame = playBot ? true : this.state.privateGame;
        let rated = this.state.numberOfPlayers === 2 && !useBot ? true : false;
        if(this.state.currentUser && this.state.currentUser.email!=undefined && this.state.currentUser.email != null){
            let useMatchmaking = this.state.useMatchmaking ? true : false;
            let body = {
                currentUser: this.state.currentUser,
                idToken: this.verifyToken(),
                useBot,
                useMatchmaking,
                privateGame,
                rated,
                numberOfPlayers: this.state.numberOfPlayers
            }
            axios.post('db/games', body)
                .then((response)=>{
                let game = response.data;
                this.setState({game, partyLeader:game.partyLeader, leftGame:false, gameId:game.gameId});
                if(!game.privateGame){
                    socket.emit("updatePublicGames", game);
                }
                let currentUser = this.state.currentUser;
                currentUser.currentGame = game.gameId;
                socket.emit("addToOnlineList", currentUser);
                axios.put(`db/onlineList/add`, {user: currentUser, idToken: this.verifyToken()})
                .then((response)=>{
                    
                }).catch(err=>{
                    console.log(err);
                })
                // this.updateGameState(game);
                if(newUser){
                    this.joinGame(newUser);
                }else if (this.state.useBot){
                    // let rand = Math.floor(Math.random()*usernames.length);
                    // let username = usernames[rand];
                    let username = "BOT"
                    let botUser = {
                        username,
                        email: "bot@botmail.com",
                        stats:[],
                        currentGame:""
                    }
                    this.joinGame(botUser)
                }
                }).catch(err=>{
                    console.log(err);
                }) 
        }else{
            this.setState({error: "Cannot create game, account error, please try creating new account"});
        }
        
    }

    toggleDropdown = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    findMatch = () => {

        let data = [];
        this.setState({inQueue:true, creatingGame:true});

        axios.get(`db/gameQueue?idToken=${this.verifyToken()}`)
        .then((response)=>{
            // this.setState({gameQueueList: response.data.gameQueueList})
            let gameQueueList = response.data.gameQueueList;
            if(gameQueueList.length!=0 && gameQueueList[0].email!=this.state.currentUser.email){
                let newUser = gameQueueList[0];
                data = {
                    email1: newUser.email,
                    email2: this.state.currentUser.email,
                    idToken: this.verifyToken()
                }
                axios.put(`db/gameQueue`, data)
                .then((response)=>{
                    clearInterval(queueTimer);
                    queueTimer = null;
                    this.setState({useMatchmaking:true, useBot:false});
                    this.createGame(newUser);
                }).catch(err=>{
                    console.log(err);
                })
               
            }else{
                let currentUser = this.state.currentUser;
                if(currentUser && currentUser.email!=null){
                    let body = {
                        currentUser,
                        idToken: this.verifyToken()
                    }
                    axios.post('db/gameQueue', body)
                        .then((response)=>{        
                            queueTimer = setInterval(() => {
                                let rand = Math.floor(Math.random()*(28-10+1))+10;
    
                                if (this.state.queueTime>rand){
                                    data = {
                                        email1: "",
                                        email2: this.state.currentUser.email,
                                        idToken: this.verifyToken()
                                    }
                                    axios.put(`db/gameQueue`, data)
                                    .then((response)=>{
                                        this.setState({useBot:true, useMatchmaking:false});
                                        clearInterval(queueTimer);
                                        queueTimer = null;
                                        this.createGame();
                                    }).catch(err=>{
                                        console.log(err);
                                    })
                                    
                                }        
                                this.setState({queueTime: this.state.queueTime+1});
                            }, 1000);
            
                            
                        }).catch(err=>{
                            console.log(err);
                        }) 
                }else{
                    this.setState({error: "Cannot find match, account error, please try creating new account"});
                }
            
            }
        }).catch(err=>{
            console.log(err);
        })
    
    }

    playAI = () => {
        this.setState({useBot:true, useMatchmaking:false, rated: false, useTimer:true, privateGame:true});
        this.createGame(null, true);
    }

    updateRating = (players) => {
        let body = {
            players,
            idToken: this.verifyToken()
        }
        
        axios.put('db/users/rating', body)
            .then((response)=>{
                socket.emit("getUser", this.state.game.gameId); 
            }).catch(err=>{
                console.log(err);
            })
    }

    endGame = (game, forfeiter) => {
        this.setState({gameEnded:true});
        clearInterval(timerHandle);
        let points;
        game.players.map(player=>{
            points = 0;
            let fullBoard = player.board.yellow.concat(player.board.blue).concat(player.board.green).concat(player.board.red).concat(player.board.purple);
            fullBoard.map((card)=>{
                let fullCard = districtCards.find(dCard=>dCard.cardName === card);
                points+=fullCard.cost;
                switch (card) {
                    case "university":
                        points+=2;
                        break;
                    case "dragonGate":
                        points+=2;
                        break;
                    case "treasury":
                        points+=player.gold;
                        break;   
                    case "mapRoom":
                        points+=player.hand.length;
                        break;  
                    case "wishingWell":
                        points+=player.board.purple.length-1;
                        break; 
                    case "hauntedCity":
                        let hauntedCityColor = game.hauntedCityColor;
                        switch (hauntedCityColor) {
                            case "yellow":
                                player.board.yellow.push("hauntedCity");
                                break;
                            case "blue":
                                player.board.blue.push("hauntedCity");
                                break;
                            case "green":
                                player.board.green.push("hauntedCity");
                                break;
                            case "red":
                                player.board.red.push("hauntedCity");
                                break;
                            case "purple":
                                player.board.purple.push("hauntedCity");
                                break;
                            default:
                                break;
                        }
                        break;  
                    default:
                        break;
                }
            })

            if(player.email === game.firstToComplete){
                points+=4;
            }else if (fullBoard.length>=game.districtsToWin) {
                points+=3;
            }

            if(player.board.yellow.length != 0 && player.board.blue.length != 0 && player.board.green.length != 0 && player.board.red.length != 0 && player.board.purple.length != 0){
                points+=3;
            }


            player.points = points;
        })

        game.players.sort((a,b)=>b.points-a.points);

        // ELO calculator
        if(game.rated && !game.gameEnded){
            if(forfeiter){
                game.forfeiter = forfeiter.username;
                let loserIndex = 0;
                let winnerIndex = 0;
                game.players.map((player,index)=>{
                    if(player.email === forfeiter.email){
                        loserIndex = index;
                    }else{
                        winnerIndex = index;
                    }
                });
                let temp = game.players[winnerIndex];
                let temp2 = game.players[loserIndex];
                game.players[0] = temp;
                game.players[1] = temp2;


            }

            // let points1 = game.players[0].points;
            // let points2 = game.players[1].points;
            // let diff = Math.abs(points1-points2);
            let elo1 = game.players[0].rating.length>0 ? game.players[0].rating.slice(-1)[0] : 1200;
            let elo2 = game.players[1].rating.length>0 ? game.players[1].rating.slice(-1)[0] : 1200;

            let base = 0;
            let additional = 0;
            let eloChange = 0;

            if (!forfeiter && game.players[0].points === game.players[1].points){
                eloChange = base + Math.abs(elo1-elo2)/20;  
                if(elo1>elo2){
                    elo1+=eloChange;
                    elo2-=eloChange;
                }else if (elo1<elo2){
                    elo2-=eloChange;
                    elo1+=eloChange;
                }
                game.players[0].draws = game.players[0].draws ? game.players[0].draws+1 : 1;
                game.players[1].draws = game.players[1].draws ? game.players[1].draws+1 : 1;
            }else{             
                if(elo1>=elo2){
                    base = 0; 
                    additional = 30-(elo1-elo2)/10 > 0 ? 20-(elo1-elo2)/10 : 0;
                    eloChange = base+additional;
                    elo1+=eloChange;
                    elo2-=eloChange;
                }else{
                    base = 0; 
                    additional = 20+(elo2-elo1)/10 < 30 ? 20+(elo2-elo1)/10 : 30;
                    eloChange = base+additional;
                    elo1+=eloChange;
                    elo2-=eloChange;
                }    
                game.players[0].wins = game.players[0].wins ? game.players[0].wins+1 : 1;
                game.players[1].losses = game.players[1].losses ? game.players[1].losses+1 : 1;      
            }

            
            game.players[0].rating.push(Math.round(elo1));
            game.players[1].rating.push(Math.round(elo2));
           
            
            // console.log( game.players[0].rating);
            // console.log( game.players[1].rating);

            

            this.updateRating(game.players);
        }

        // game.winner = game.players[0].email;
        game.gameEnded = true;
        this.updateGameState(game);

    }

    leaveGame = () => {
        let game = Object.assign({}, this.state.game);
        let currIndex = 0;
        game.players.map((player, index)=>{
            if(player.email===this.state.currentPlayer.email){
                currIndex = index;
            }
        })
        game.players.splice(currIndex,1);
        let body = {
            email: this.state.currentUser.email,
            idToken: this.verifyToken()
        }
        axios.put('db/users', body)
            .then((response)=>{
                let newUser = response.data;
                socket.emit("addToOnlineList", newUser);
                axios.put(`db/onlineList/add`, {user: newUser, idToken: this.verifyToken()})
                .then((response)=>{
                    
                }).catch(err=>{
                    console.log(err);
                })    
            }).catch(err=>{
                console.log(err);
            })

        this.setState({creatingGame:false, gameId:null, useBot:false, useMatchmaking:false, messages:[], game:{gameId:"temp"}, rated:false, sentChallengesList:[]});
        this.updateGameState(game);
    }

    deleteGame = () => {
        if(window.confirm("Are you sure you want to delete the game?")){
            let game = Object.assign({}, this.state.game);
            this.setState({useBot:false, useMatchmaking:false, creatingGame:false, messages: [], sentChallengesList:[]});
            
            let gameId = game.gameId != "temp" ? game.gameId : this.state.gameId;
    
            axios.delete(`db/games?idToken=${this.verifyToken()}&email=${this.state.currentUser.email}&gameId=${gameId}`)
            .then((response)=>{
                socket.emit("deleteGame", response.data); 
            }).catch(err=>{
                console.log(err);
            })
        }
    }

    exitGame = () => {    
        if (window.confirm("If you leave the game you cannot rejoin. If the game is still in progress and is rated, you will automatically lose and concede rating points. Are you sure you want to leave?")){
            this.setState({leftGame:true});
            let body = {
                email: this.state.currentHumanPlayer.email,
                idToken: this.verifyToken()
            }
            axios.put('db/users', body)
            .then((response)=>{
                this.setState({exitedGame:true, creatingGame:false, actionLog:[], currentAction:"", gameId:null, game:{gameId:"temp"}, useBot:false, rated:false, sentChallengesList:[]});
                if(!this.state.game.gameEnded && this.state.game.rated){
                    this.endGame(this.state.game, this.state.currentUser);
                }
                let newUser = response.data;
                socket.emit("addToOnlineList", newUser);
                axios.put(`db/onlineList/add`, {user: newUser, idToken: this.verifyToken()})
                .then((response)=>{
                    
                }).catch(err=>{
                    console.log(err);
                })    
            }).catch(err=>{
                console.log(err);
            })
        }else{

        }
        
    }

    sendChallenge = (email) => {
        if(this.state.game.gameId != "temp" || this.state.gameId){
            let sentChallengesList = this.state.sentChallengesList;
            sentChallengesList.push(email);
            this.setState({sentChallengesList});
            let currentUser = this.state.currentUser;
            let rating = currentUser.rating && currentUser.rating.length>0 ? currentUser.rating.slice(-1) : "unrated";
            let challengeObj = {
                challenger: currentUser.username,
                game: this.state.game,
                challenged: email,
                rating
            }
            socket.emit("sendChallenge", challengeObj); 

        }else{
            alert("Create Game To Send Challenge");
        }
       
        
    }

    removeChallenge = (gameId) => {
        let receivedChallengesList = this.state.receivedChallengesList;
        receivedChallengesList = receivedChallengesList.filter(challenge=>challenge.game.gameId!=gameId);
        this.setState({receivedChallengesList});
    }

    render(){
        return(
            <div id="mainBox">
                {/* <Beforeunload onBeforeunload={() => this.leaveGame()} /> */}
                {
                    this.state.game && this.state.currentUser && this.state.currentUser.email!=undefined && (this.state.gameStarted || this.state.game.gameStarted) && !this.state.exitedGame ? (
                        <div id="gameBackground">

                            <div id="gameContainer">
                                <div id="actionBox">
                                    <div>{this.state.currentAction ? (<h2 dangerouslySetInnerHTML={{__html: this.state.currentAction}}></h2>) : (<h2 dangerouslySetInnerHTML={{__html: this.state.game.currentAction}}></h2>) }</div>
                                </div>
                                <div id="boardBox">
                            

                                <div id="boardInnerBox" className="row">
                                    <div id="boardTitleBox">{
                                        this.state.currentUser.email === this.state.game.players[this.state.playerIndex].email ? (
                                            <h2 className="boardTitle"><span className="boardTitleName">Your Board</span></h2>
                                        ) : (
                                            <h2 className="boardTitle"><span className="boardTitleName">{this.state.game.players[this.state.playerIndex].username}'s</span> Board</h2>
                                        )
                                    }
                                    </div>
                                    <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12" id="leftBoard">
                                        {
                                            
                                            this.state.currentPlayer.board && (
                                                <div id="boardContainer">
                                                    <div className="boardColorBox">
                                                        {
                                                    
                                                            this.state.game.players[this.state.playerIndex].board.yellow.map((card)=>{
                                                                
                                                                let fullCard = districtCards.find(dCard=>dCard.cardName === card);

                                                                return  <div className="boardInnerContainer">
                                                                            <div id="characterChooseBox">
                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase==="destroying" && this.state.game.players[this.state.playerIndex].email != this.state.currentPlayer.email && this.state.currentPlayer.currentCharacter === "warlord" && this.state.game.players[this.state.playerIndex].character1 != "bishop" && this.state.game.players[this.state.playerIndex].character2 != "bishop" && fullCard.cardName != "keep" && !this.state.destroyingDistrict && (
                                                                                        this.state.game.players[this.state.playerIndex].board.purple.includes("greatWall") ? (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        ) : (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost - 1) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <img onClick={()=>this.openHandDistrictCardMenu(fullCard)} className="boardCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                        </div>
                                                                                        
                                                            })
                                                        }
                                                    </div>
                                                    <div className="boardColorBox">
                                                        {
                                                            this.state.game.players[this.state.playerIndex].board.blue.map((card)=>{
                                                        
                                                                let fullCard = districtCards.find(dCard=>dCard.cardName === card);
            
                                                                return  <div className="boardInnerContainer">
                                                                            <div id="characterChooseBox">
                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase==="destroying" && this.state.game.players[this.state.playerIndex].email != this.state.currentPlayer.email && this.state.currentPlayer.currentCharacter === "warlord" && this.state.game.players[this.state.playerIndex].character1 != "bishop" && this.state.game.players[this.state.playerIndex].character2 != "bishop" && fullCard.cardName != "keep" && !this.state.destroyingDistrict && (
                                                                                        this.state.game.players[this.state.playerIndex].board.purple.includes("greatWall") ? (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        ) : (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost - 1) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <img onClick={()=>this.openHandDistrictCardMenu(fullCard)} className="boardCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                        </div>
                                                                                        
                                                            })
                                                        }
                                                    </div>
                                                    <div className="boardColorBox">
                                                        {
                                                            this.state.game.players[this.state.playerIndex].board.green.map((card)=>{
                                                        
                                                                let fullCard = districtCards.find(dCard=>dCard.cardName === card);
            
                                                                return  <div className="boardInnerContainer">
                                                                            <div id="characterChooseBox">
                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase==="destroying" && this.state.game.players[this.state.playerIndex].email != this.state.currentPlayer.email && this.state.currentPlayer.currentCharacter === "warlord" && this.state.game.players[this.state.playerIndex].character1 != "bishop" && this.state.game.players[this.state.playerIndex].character2 != "bishop" && fullCard.cardName != "keep" && !this.state.destroyingDistrict &&(
                                                                                        this.state.game.players[this.state.playerIndex].board.purple.includes("greatWall") ? (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        ) : (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost - 1) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <img onClick={()=>this.openHandDistrictCardMenu(fullCard)} className="boardCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                        </div>
                                                                                        
                                                            })
                                                        }
                                                    </div>
                                                    <div className="boardColorBox">
                                                        {
                                                            this.state.game.players[this.state.playerIndex].board.red.map((card)=>{
                                                        
                                                                let fullCard = districtCards.find(dCard=>dCard.cardName === card);
            
                                                                return  <div className="boardInnerContainer">
                                                                            <div id="characterChooseBox">
                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase==="destroying" && this.state.game.players[this.state.playerIndex].email != this.state.currentPlayer.email && this.state.currentPlayer.currentCharacter === "warlord" && this.state.game.players[this.state.playerIndex].character1 != "bishop" && this.state.game.players[this.state.playerIndex].character2 != "bishop" && fullCard.cardName != "keep" && !this.state.destroyingDistrict && (
                                                                                        this.state.game.players[this.state.playerIndex].board.purple.includes("greatWall") ? (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        ) : (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost - 1) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <img onClick={()=>this.openHandDistrictCardMenu(fullCard)} className="boardCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                        </div>
                                                                                        
                                                            })
                                                        }
                                                    </div>
                                                    <div className="boardColorBox">
                                                        {
                                                            this.state.game.players[this.state.playerIndex].board.purple.map((card)=>{
                                                        
                                                                let fullCard = districtCards.find(dCard=>dCard.cardName === card);
            
                                                                return  <div className="boardInnerContainer">
                                                                            <div id="characterChooseBox">
                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase==="destroying" && this.state.game.players[this.state.playerIndex].email != this.state.currentPlayer.email && this.state.currentPlayer.currentCharacter === "warlord" && this.state.game.players[this.state.playerIndex].character1 != "bishop" && this.state.game.players[this.state.playerIndex].character2 != "bishop" && fullCard.cardName != "keep" && !this.state.destroyingDistrict &&(
                                                                                        this.state.game.players[this.state.playerIndex].board.purple.includes("greatWall") ? (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        ) : (
                                                                                            this.state.currentPlayer.gold >= (fullCard.cost - 1) ? (
                                                                                                <button className="generalButton" onClick={()=>this.destroyCard(fullCard)} id="chooseCharacterButton">Destroy District</button>
                                                                                            ) : (
                                                                                                <button className="generalButton" id="chooseCharacterButton">Not Enough Gold To Destroy</button>
                                                                                            )
                                                                                        )
                                                                                        
                                                                                    )
                                                                                }
                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentHumanPlayer.email && this.state.game.players[this.state.playerIndex].email === this.state.currentHumanPlayer.email && this.state.game.currentPhase === "buildingPhase" && (
                                                                                        (card === "hauntedCity" || card === "graveyard" || card === "laboratory" || card === "smithy") && this.state.game.currentPhase != "waitingForHauntedCity" && (  
                                                                                            <div style={{"textAlign":"center"}}>
                                                                                                <div>
                                                                                                    {
                                                                                                        card==="laboratory" && !this.state.game.usedLaboratory && !this.state.usedLaboratory && this.state.currentPlayer.hand.length>0 && (
                                                                                                            <button className="generalButton" onClick={()=>this.useEffect(fullCard)} id="chooseCharacterButton">Use Effect</button>      
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        card==="graveyard" && !this.state.game.usedGraveyard && !this.state.usedGraveyard && this.state.currentPlayer.gold > 0 && (
                                                                                                            <button className="generalButton" onClick={()=>this.useEffect(fullCard)} id="chooseCharacterButton">Use Effect</button>      
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        card==="smithy" &&  !this.state.game.usedSmithy && !this.state.usedSmithy && this.state.currentPlayer.gold >= 2 && (
                                                                                                            <button className="generalButton" onClick={()=>this.useEffect(fullCard)} id="chooseCharacterButton">Use Effect</button>      
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        card==="hauntedCity" && (
                                                                                                            <button className="generalButton" onClick={()=>this.useEffect(fullCard)} id="chooseCharacterButton">Use Effect</button>      
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )   
                                                                                    )
                                                                                }
                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentPlayer.email && card==="hauntedCity" && this.state.game.currentPhase === "waitingForHauntedCity" && (
                                                                                        <div id="hauntedCityBox">
                                                                                            <div id="chooseColorButton">Choose Color</div>
                                                                                            <div id="colorBox">
                                                                                                <button onClick={()=>this.changeCardColor("yellow")} style={{"backgroundColor":"yellow"}} className="colorButton"></button>     
                                                                                                <button onClick={()=>this.changeCardColor("blue")} style={{"backgroundColor":"blue"}} className="colorButton"></button>      
                                                                                                <button onClick={()=>this.changeCardColor("green")} style={{"backgroundColor":"green"}} className="colorButton"></button>      
                                                                                                <button onClick={()=>this.changeCardColor("red")} style={{"backgroundColor":"red"}} className="colorButton"></button>      
                                                                                                <button onClick={()=>this.changeCardColor("purple")} style={{"backgroundColor":"purple"}} className="colorButton"></button>      
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {
                                                                                    this.state.game.currentPlayerEmail === this.state.currentPlayer.email && card==="hauntedCity" && this.state.game.currentPhase === "buildingPhase" && (
                                                                                        <div id="hauntedCityBox">    
                                                                                            <div id="colorBox">
                                                                                                <button style={{"backgroundColor":`${this.state.game.hauntedCityColor}`}} className="colorButton"></button>           
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <img onClick={()=>this.openHandDistrictCardMenu(fullCard)} className="boardCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                        </div>
                                                                                        
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12" id="rightBoard">
                                        {
                                            this.state.showTimer && (
                                                <div id="timerContainer">
                                                    <h5 id="turnTimerTitle"><strong>Turn Timer</strong></h5>
                                                    {
                                                        this.state.timer<20 ? (
                                                            this.state.timer<10 ? (
                                                                <div id="timerBoxRed">                                                    
                                                                    <h1>{this.state.timer}</h1>
                                                                </div>
                                                            ) : (
                                                                <div id="timerBoxYellow">                                                    
                                                                    <h1>{this.state.timer}</h1>
                                                                </div>
                                                            )
                                                        ) : (
                                                            <div id="timerBoxGreen">                                                    
                                                                <h1>{this.state.timer}</h1>
                                                            </div>
                                                        )
                                                    }
                                                   
                                                </div>
                                            )
                                        }
                                        <div id="rightTopBoard">
                                            <div>
                                                <button onClick={()=>this.nextBoard("left")} className="arrowButtons">&#171;</button>
                                                <button onClick={()=>this.nextBoard("right")} className="arrowButtons">&#187;</button>
                                            </div>
                                            {
                                                this.state.currentHumanPlayer.email === this.state.game.currentPlayerEmail && this.state.game.currentPhase === "buildingPhase" && !this.state.endTurnClicked && (
                                                    <button onClick={()=>this.nextTurn()} id="endTurnButton">End Turn</button>
                                                )
                                            }
                                        </div>
                                        <div id="rightBotBoard">
                                            <div id="goldContainer">                              
                                                <div id="goldDisplay">
                                                    <img id="boardGoldImage" src="images/miscallaneous_assets/goldCoin.png"></img> 
                                                    <h5><span className="arrowBoxLabels">Gold</span> <span className="colon">:</span> <span id="goldValue">{this.state.game.players[this.state.playerIndex].gold}</span></h5>
                                                </div>                         
                                            </div>

                                            <div id="goldContainer">                              
                                                <div id="goldDisplay">
                                                    <img id="boardGoldImage" src="images/district_cards/handImage.png"></img> 
                                                    <h5><span className="arrowBoxLabels">Hand</span> <span className="colon">:</span> <span id="goldValue">{this.state.game.players[this.state.playerIndex].hand.length}</span></h5>
                                                </div>                         
                                            </div>

                                            <div id="goldContainer">                              
                                                <div id="goldDisplay"> 
                                                    <div>
                                                        <img id="boardCharacterImage" src="images/character_cards/characterFaceDown.PNG"></img>
                                                        {this.state.game.numberOfPlayers>3 ? (<h5 className="roleTitle">Role: </h5>) : (<h5 className="roleTitle">Role 1: </h5>)}
                                                    </div>
                                                    {   this.state.game.players[this.state.playerIndex].showChar1 ? (
                                                        <h5><span id="goldValue">{this.state.game.players[this.state.playerIndex].character1}</span></h5>
                                                    ) : (
                                                        <h5><span id="goldValue">?</span></h5>
                                                    )
                                                    }
                                                </div>                         
                                            </div>
                                            { this.state.game.numberOfPlayers<=3 && (
                                                <div id="goldContainer">                              
                                                    <div id="goldDisplay"> 
                                                        <div>
                                                            <img id="boardCharacterImage" src="images/character_cards/characterFaceDown.PNG"></img>
                                                            <h5 className="roleTitle">Role 2: </h5>
                                                        </div>
                                                        {   this.state.game.players[this.state.playerIndex].showChar2 ? (
                                                            <h5><span id="goldValue">{this.state.game.players[this.state.playerIndex].character2}</span></h5>
                                                        ) : (
                                                            <h5><span id="goldValue">?</span></h5>
                                                        )
                                                        }
                                                    </div> 
                                                                    
                                                </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                

                                    
                                </div>
                                
                                <div id="infoBox">
                                {
                                    this.state.game.currentPhase === "waitingForPlayerChoosing" && (
                                        <div id="turnOrderBox">
                                            <div id="turnOrderTitle">Turn Order: </div>
                                            <div>
                                                { this.state.game.players.map(player=>{
                                                    if(player.email === this.state.game.currentPlayerEmail){
                                                        return <div className="turnOrderSelectedElement">
                                                            <div id="turnOrderInnerElement">
                                                                <img id="turnOrderArrow" src="images/miscallaneous_assets/turnOrderArrow.svg"></img>
                                                                <div id="turnOrderElementName">{player.username.substr(0,8)} {this.state.game.rated && (player.rating[0] ? `(${player.rating.slice(-1)[0]})`:1200)}</div>
                                                            </div>
                                                            </div>
                                                    }else {
                                                        return <div className="turnOrderElement">{player.username.substr(0,8)} {this.state.game.rated && (player.rating[0] ? `(${player.rating.slice(-1)[0]})`:1200)}</div>
                                                    }
                                                    
                                                })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                    {
                                        this.state.game.currentPlayerEmail === this.state.currentUser.email && (this.state.game.currentPhase === "waitingForPlayerChoosing" || this.state.game.currentPhase === "waitingForPlayerDiscarding") && !this.state.choseCards ? (
                                            <div id="characterOuterBox">
                                                <div id="toggleCharactersButtonBox">
                                                    {
                                                        this.state.viewDiscardedCards ? (
                                                            <button className="toggleCharactersButton" onClick={()=>this.toggleCharacterCards()}>View <u>Available</u> Character Cards</button>
                                                        ) : (
                                                            this.state.game.numberOfPlayers <= 6 && (
                                                                <button className="toggleCharactersButton" onClick={()=>this.toggleCharacterCards()}>View <u>Discarded</u> Character Cards</button>
                                                            )
                                                        )
                                                    }
                                                </div>
                                                
                                                <div id="characterCardContainerOuter">
                                                    {
                                                        this.state.viewDiscardedCards ? (
                                                            <div id="characterCardContainer">
                                                                {
                                                                    this.state.game.characterDeck.faceDown.map(()=>{
                                                                        return <div className="characterCardBox">
                                                                            <img className="characterCard" src={`images/character_cards/characterFaceDown.PNG`}></img>
                                                                        </div>
                                                                    })
                                                                }
                                                                {
                                                                    (this.state.game.numberOfPlayers === 4 || this.state.game.numberOfPlayers === 5) && (
                                                                        <div id="characterDivider"></div>
                                                                    )
                                                                }
                                                                {
                                                                    this.state.game.characterDeck.faceUp.map((card)=>{
                                                                        let fullCard = characterCards.find(cCard=>cCard.cardName === card);
                                                                        return <div className="characterCardBox">
                                                                            
                                                                            <div id="characterChooseBox">
                                                                                <button id="characterInfoButton" onClick={()=>this.showCardInfo(fullCard)}><img id="characterInfoImage" src="images/miscallaneous_assets/info.svg" /></button>
                                                                            </div>
                                                                                
                                                                            <img className="characterCard" src={`images/character_cards${fullCard.img}.PNG`}></img>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div id="characterCardContainer">
                                                                {
                                                                    this.state.game.characterDeck.available.map((card)=>{
                                                                        let fullCard = characterCards.find(cCard=>cCard.cardName === card);
                                                                        return <div className="characterCardBox">
                                                                            {
                                                                                this.state.currentCharacterCard === fullCard.cardName && (
                                                                                    <div id="characterChooseBox">
                                                                                        <button id="characterInfoButton" onClick={()=>this.showCardInfo(fullCard)}><img id="characterInfoImage" src="images/miscallaneous_assets/info.svg" /></button>
                                                                                        {
                                                                                            this.state.game.currentPhase === "waitingForPlayerChoosing" ? (
                                                                                                <button onClick={()=>this.chooseCharacterCard(fullCard)} id="chooseCharacterButton">Choose Card</button>
                                                                                            ):(
                                                                                                <button onClick={()=>this.discardCharacterCard(fullCard)} id="chooseCharacterButton">Discard Card</button>
                                                                                            )
                                                                                            
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <img onClick={()=>this.openCharacterCardMenu(fullCard.cardName)} className="characterCard" src={`images/character_cards${fullCard.img}.PNG`}></img>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            </div>
              
                                        ) : (
                                            <div id="infoContainer">
                                                <div id="infoInnerContainer">
                                                    {
                                                        this.state.game.discardPile.length > 0 && (
                                                            <div className="infoInnerBox">
                                                                <button onClick={()=>this.toggleDiscardPile()} id="discardPileButton">View Discard Pile : <span id="goldValue">{this.state.game.discardPile.length}</span></button>
                                                            </div>
                                                        )
                                                    }   
                                                    <div className="infoInnerBox" id="deckBox">
                                                        <img id="districtDeckImage" src="images/miscallaneous_assets/deck.png"></img>
                                                        <h6>Cards In Deck: <span id="districtDeckSize">{this.state.game.districtDeck.length}</span></h6>
                                                    </div>
                                                    <div className="infoInnerBox">
                                                        <img id="kingTokenImage" src="images/miscallaneous_assets/crownToken.png"></img>
                                                        {
                                                            this.state.game.showCurrentKing ? (
                                                                <h6>King's Heir: <br/> <strong id="goldValue">{this.state.game.currentKingName.substr(0,8)}</strong></h6>
                                                            ) : (
                                                                <h6>King's Heir: <strong id="goldValue">?</strong></h6>
                                                            )
                                                        }
                                                    </div>
                                                
                                                    <div className="infoInnerBox">
                                                        <div id="infoGoldContainer">
                                                            <img id="infoGoldImage" src="images/miscallaneous_assets/goldCoin.png" ></img>
                                                            <h2>{this.state.currentHumanPlayer.gold}</h2>
                                                        </div>
                                                        <h6>Your Gold</h6>
                                                    </div>
                                                    {

                                                        this.state.currentHumanPlayer.character1 && this.state.character1 && (

                                                            <div className="infoInnerBox">
                                                                
                                                                <div className="currCharacterCardBox">
                                                                    <div id="characterChooseBox">
                                                                    
                                                                        <button id="characterInfoButton" className="generalButton" onClick={()=>this.showCardInfo(this.state.character1)}><img id="characterInfoImage" src="images/miscallaneous_assets/info.svg" /></button>

                                                                        {
                                                                            this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "buildingPhase" && this.state.currentPlayer.currentCharacter === this.state.character1.cardName && this.state.currentPlayer.currentCharacter!="assassin" && this.state.currentPlayer.currentCharacter!="thief" && this.state.currentPlayer.currentCharacter!="architect" && this.state.currentPlayer.currentCharacter!="magician" && this.state.game.usedPower===false && !this.state.earnedGold && (
                                                                                    <button className="generalButton" onClick={()=>this.usePower(this.state.currentCharacter.cardName)} id="chooseCharacterButton">Earn Gold</button>
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "buildingPhase" && this.state.currentPlayer.currentCharacter === this.state.character1.cardName && this.state.currentPlayer.currentCharacter==="magician" && this.state.game.usedPower===false && !this.state.tradedCards && (
                                                                                    <button className="generalButton" onClick={()=>this.usePower(this.state.currentCharacter.cardName)} id="chooseCharacterButton">Trade Cards</button>
                                                                            )
                                                                        }

                                                                        {
                                                                            this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "buildingPhase" && this.state.currentPlayer.currentCharacter === this.state.character1.cardName && this.state.currentPlayer.currentCharacter==="warlord" && this.state.game.districtsDestroyed===0 && !this.state.destroyedDistrict && (
                                                                                    <button  className="generalButton" onClick={()=>this.usePower(this.state.currentCharacter.cardName, "destroy")} id="chooseCharacterButton">Destroy District</button>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <img className="characterCard" src={`images/character_cards${this.state.character1.img}.PNG`}></img>

                                                                </div>
                                                                <h6>Role 1</h6>
                                                            </div>

                                                        )

                                                        
                                                    }

                                                    {

                                                    this.state.currentHumanPlayer.character2 && this.state.character2 && (

                                                        <div className="infoInnerBox">
                                                            
                                                            <div className="currCharacterCardBox">
                                                                <div id="characterChooseBox">
                                                                
                                                                    <button id="characterInfoButton" className="generalButton" onClick={()=>this.showCardInfo(this.state.character2)}><img id="characterInfoImage" src="images/miscallaneous_assets/info.svg" /></button>

                                                                    {
                                                                        this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "buildingPhase" && this.state.currentPlayer.currentCharacter === this.state.character2.cardName && this.state.currentPlayer.currentCharacter!="assassin" && this.state.currentPlayer.currentCharacter!="thief" && this.state.currentPlayer.currentCharacter!="architect" && this.state.currentPlayer.currentCharacter!="magician" && this.state.game.usedPower===false && !this.state.earnedGold && (
                                                                                <button className="generalButton" onClick={()=>this.usePower(this.state.currentCharacter.cardName)} id="chooseCharacterButton">Earn Gold</button>
                                                                        )
                                                                    }
                                                                    {
                                                                        this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "buildingPhase" && this.state.currentPlayer.currentCharacter === this.state.character2.cardName && this.state.currentPlayer.currentCharacter==="magician" && this.state.game.usedPower===false && !this.state.tradedCards && (
                                                                                <button className="generalButton" onClick={()=>this.usePower(this.state.currentCharacter.cardName)} id="chooseCharacterButton">Trade Cards</button>
                                                                        )
                                                                    }

                                                                    {
                                                                        this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "buildingPhase" && this.state.currentPlayer.currentCharacter === this.state.character2.cardName && this.state.currentPlayer.currentCharacter==="warlord" && this.state.game.districtsDestroyed===0 && !this.state.destroyedDistrict && (
                                                                                <button  className="generalButton" onClick={()=>this.usePower(this.state.currentCharacter.cardName, "destroy")} id="chooseCharacterButton">Destroy District</button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <img className="characterCard" src={`images/character_cards${this.state.character2.img}.PNG`}></img>

                                                            </div>
                                                            <h6>Role 2</h6>
                                                        </div>

                                                        )


                                                    }
                                                </div>
                                                {
                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "waitingForPlayerAction" && this.state.game.usedAction===false && !this.state.usedAction && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Choose An Action</h3>
                                                            <div>
                                                                <button onClick={()=>this.actionDrawCard()} className="boardActionButton">Draw Card</button>
                                                                <button onClick={()=>this.actionGetGold()} className="boardActionButton">2 Gold</button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "waitingForCardDraw" &&  this.state.game.usedAction===false && !this.state.drewCard && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Choose 1 out of the following {this.state.game.districtChoice.length} Cards to draw</h3>
                                                            <div>
                                                                <div id="characterCardContainer">
                                                                    {
                                                                        this.state.game.districtChoice.map(card=>{
                                                                            let fullCard = districtCards.find(dCard=>dCard.cardName === card);
                                                                            return <div className="characterCardBox">
                                                                                        {
                                                                                            fullCard.cardName === this.state.currentDistrictCard && (
                                                                                                <div id="characterChooseBox">
                                                                                                    {/* <button id="characterInfoButton"><img id="characterInfoImage" src="images/miscallaneous_assets/info.svg" /></button> */}
                                                                                                    <button onClick={()=>this.drawDistrictCard(fullCard.cardName)} id="chooseCharacterButton">Draw Card</button>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <img onClick={()=>this.openDistrictCardMenu(fullCard.cardName)} className="characterCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                                    </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "assassinating" && !this.state.assassinationComplete && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Choose someone to assassinate</h3>
                                                            <div id="boardActionInnerBox">
                                                                {
                                                                    characterCards.map(cCard=>{
                                                                        if(cCard.cardName!="assassin" && cCard.cardName!=this.state.currentHumanPlayer.character1 && cCard.cardName != this.state.currentHumanPlayer.character2){
                                                                            return <div onClick={()=>this.assassinatePlayer(cCard.cardName)} className="characterChoices">
                                                                                {cCard.cardName.substr(0,1).toUpperCase() + cCard.cardName.substr(1)}
                                                                            </div>
                                                                        }
                                                                        
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "stealing" && !this.state.stealingComplete && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Choose someone to steal from</h3>
                                                            <div id="boardActionInnerBox">
                                                                {
                                                                    characterCards.map(cCard=>{
                                                                        if(cCard.cardName!= "thief" && cCard.cardName != "assassin" && cCard.cardName!=this.state.currentHumanPlayer.character1 && cCard.cardName != this.state.currentHumanPlayer.character2){
                                                                            return <div onClick={()=>this.stealFromPlayer(cCard.cardName)} className="characterChoices">
                                                                                {cCard.cardName.substr(0,1).toUpperCase() + cCard.cardName.substr(1)}
                                                                            </div>
                                                                        }
                                                                        
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "trading" && !this.state.madeTrade && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Trade hands with any player or exchange cards with the deck</h3>
                                                            <div id="boardActionInnerBox">
                                                                {
                                                                    this.state.game.players.map(player=>{
                                                                        if(player.email!=this.state.currentPlayer.email){
                                                                            return <div onClick={()=>this.tradeHandWithPlayer(player)} className="characterChoices">
                                                                                {player.username}
                                                                            </div>
                                                                        }
                                                                        
                                                                    })
                                                                }
                                                                <div className="choiceSeperator"></div>
                                                                <div onClick={()=>this.tradeHandWithDeck()} className="characterChoices">
                                                                    <strong>DECK</strong>
                                                                </div>
                                                            </div>
                                                            <button className="characterChoices" onClick={()=>this.cancelAction()}>Cancel</button>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "tradingWithDeck" && !this.state.madeTrade && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Choose which cards you want to exchange with the deck</h3>
                                                            <div id="boardActionInnerBox">
                                                                {
                                                                    this.state.handPile.map(currCard=>{
                                                                        let fullCard = districtCards.find(dCard=>dCard.cardName === currCard.card);
                                                                        let tradingCard = this.state.tradePile.find(tradePileCard=>tradePileCard.cardIndex === currCard.cardIndex);

                                                                        return <div>
                                                                                    {
                                                                                        tradingCard ? (
                                                                                            <div  className="tradingPileCardsHighlighted">
                                                                                                <img onClick={()=>this.toggleTradePile(currCard)} className="tradeHandCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div  className="tradingPileCards">
                                                                                                <img onClick={()=>this.toggleTradePile(currCard)} className="tradeHandCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                       
                                                                    })
                                                                }
                                                                
                                                            </div>
                                                            <div onClick={()=>this.executeTradeWithDeck()} className="tradeButton">
                                                                <strong>Trade</strong>
                                                            </div>
                                                            <button className="tradeButton" onClick={()=>this.cancelAction()}>Cancel</button>

                                                        </div>
                                                    )
                                                }

                                                {
                                                    this.state.game.currentPlayerEmail === this.state.currentUser.email && this.state.game.currentPhase === "destroying" && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Choose a district to destroy from an opponent's board above</h3>
                                                            <button className="characterChoices" onClick={()=>this.cancelAction()}>Cancel</button>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    this.state.discardPileOpen && (
                                                        <div id="boardActionBox">
                                                            <h3 id="boardActionBoxTitle">Discard Pile</h3>
                                                            <div id="boardActionInnerBox">
                                                                {
                                                                    this.state.game.discardPile.map(card=>{
                                                                        let fullCard = districtCards.find(dCard=>dCard.cardName === card);
                                                                        return <div id="discardPileBox">
                                                                                    <div id="chooseCardButton">
                                                                                        {
                                                                                            this.state.game.currentPlayerEmail === this.state.currentPlayer.email && this.state.game.currentPhase === "waitingForGraveyard" && (
                                                                                                <button className="generalButton" onClick={()=>this.getCardFromGraveyard(card)} id="chooseCharacterButton">Choose Card</button>
                                                                                            )
                                                                                            
                                                                                        }
                                                                                    </div>
                                                                                    <img className="tradingPileCards" src={`images/district_cards${fullCard.img}.PNG`}></img>   
                                                                                </div>
                                                                    })
                                                                }     
                                                            </div>
                                                        
                                                            <button className="tradeButton" onClick={()=>this.toggleDiscardPile()}>Close</button>

                                                        </div>
                                                    )
                                                }
                                                
                                                
                                            </div>
                                        )
                                    }

                                    
                                  
                                    

                                </div>
                                <div id="handBox" >
                                    {
                                        this.state.currentHumanPlayer && this.state.currentHumanPlayer.hand.length === 0 ? (
                                            <h2 id="handTitle">Your Hand Is Empty</h2>
                                        ) : (
                                            <h2 id="handTitle">Your Hand</h2>
                                        )
                                    }
                                    <div id="handCardsBox">
                                        {
                                            this.state.currentHumanPlayer && this.state.currentHumanPlayer.hand.map((card)=>{
                                                let fullCard = districtCards.find(dCard=>dCard.cardName === card);
                                                return <div className="handCardBox">
                                                            {
                                                                 this.state.game.currentPhase === "buildingPhase" && this.state.game.currentPlayerEmail === this.state.currentPlayer.email && (
                                                                    <div id="districtChooseBox">
                                                                        {
                                                                            fullCard.cardName === this.state.currentHandDistrictCard && (
                                                                                <div >
                                                                                    {/* <button id="characterInfoButton"><img id="characterInfoImage" src="images/miscallaneous_assets/info.svg" /></button> */}
                                                                                 
                                                                                    {
                                                                                        this.state.currentPlayer.currentCharacter === "architect" ? (
                                                                                            this.state.game.districtsBuilt<3 && !this.state.builtDistrict && (
                                                                                                fullCard.color === "purple" && this.state.currentPlayer.board.purple.includes("factory") ? (
                                                                                                    fullCard.cost - 1 > this.state.currentPlayer.gold ? (
                                                                                                        <button id="chooseCharacterButton">Not Enough Gold</button>
                                                                                                    ) : (
                                                                                                        <button onClick={()=>this.buildDistrict(fullCard)} id="chooseCharacterButton">Build District</button>
                                                                                                    )  
                                                                                                ) : (
                                                                                                    fullCard.cost > this.state.currentPlayer.gold ? (
                                                                                                        <button id="chooseCharacterButton">Not Enough Gold</button>
                                                                                                    ) : (
                                                                                                        <button onClick={()=>this.buildDistrict(fullCard)} id="chooseCharacterButton">Build District</button>
                                                                                                    )  
                                                                                                )
                                                                                              
                                                                                            )
                                                                                        ) : (
                                                                                            this.state.game.districtsBuilt===0 && !this.state.builtDistrict && (
                                                                                                fullCard.color === "purple" && this.state.currentPlayer.board.purple.includes("factory") ? (
                                                                                                    fullCard.cost - 1 > this.state.currentPlayer.gold ? (
                                                                                                        <button id="chooseCharacterButton">Not Enough Gold</button>
                                                                                                    ) : (
                                                                                                        <button onClick={()=>this.buildDistrict(fullCard)} id="chooseCharacterButton">Build District</button>
                                                                                                    )  
                                                                                                ) : (
                                                                                                    fullCard.cost > this.state.currentPlayer.gold ? (
                                                                                                        <button id="chooseCharacterButton">Not Enough Gold</button>
                                                                                                    ) : (
                                                                                                        <button onClick={()=>this.buildDistrict(fullCard)} id="chooseCharacterButton">Build District</button>
                                                                                                    )  
                                                                                                )
                                                                                            )
                                                                                        )   
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )

                                                            }

                                                            {
                                                                this.state.game.currentPhase === "waitingForLaboratory" && this.state.game.currentPlayerEmail === this.state.currentPlayer.email && (
                                                                    <div id="districtChooseBox">
                                                                        <button onClick={()=>this.tradeCardForGold(card)} id="chooseCharacterButton">Choose Card</button>
                                                                    </div>
                                                                )
                                                            }
                                                            <img onClick={()=>this.openHandDistrictCardMenu(fullCard.cardName)} className="handCard" src={`images/district_cards${fullCard.img}.PNG`}></img>
                                                        </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            

                            
                                {
                                    this.state.game.currentPhase != "setup" && (
                                        <div id="gameOptionsBox" className="row" style={{"marginRight":"0", "marginLeft":"0"}}>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"> 

                                            </div>
                                            <div id="gameOptionsInnerBox" className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 row" style={{"marginRight":"0", "marginLeft":"0"}}>
                                                {
                                                    this.state.viewActionLog && (
                                                        <div id="gameLogBox">
                                                            <h4>Game Log</h4>
                                                            <ul>
                                                                {
                                                                    this.state.actionLog.map(action=>{
                                                                        return <li dangerouslySetInnerHTML={{__html: action}}></li>
                                                                    })
                                                                }
                                                            </ul>
                                                            <button onClick={()=>this.toggleActionLog()} className="gameLogButtonBox">Close</button>
                                                            <div style={{ float:"left", clear: "both" }}
                                                                ref={el => { this.messagesEnd = el }}>
                                                            </div> 
                                                        </div>
                                                    )
                                                }

                                                {
                                                this.state.viewChat && (
                                                        <div id="gameChatBox">
                                                            <h4>Chat</h4>
                                                            {
                                                                this.state.messages.map(message=>{
                                                                    return <div className="messageBox">
                                                                        <div><strong>{message.name}:</strong></div>
                                                                        <div>{message.message}</div>
                                                                    </div>
                                                                })
                                                            }
                                                            
                                                            <input id="chatInput" onChange={evt=>this.handleChange("currentChatMessage", evt.target.value)} onKeyPress={this.handleKeyPress} placeholder="type"></input>
                                                            
                                                            <button onClick={()=>this.toggleChat()} className="gameLogButtonBox">Close</button>
                                                            <div style={{ float:"left", clear: "both" }}
                                                                ref={el => { this.messagesEnd = el }}>
                                                            </div> 
                                                        </div>
                                                    )
                                                }
                                                
                                                    
                                                {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div onClick={()=>this.logout()} className="gameLogButtonBox">
                                                        <img id="logImage" src="images/miscallaneous_assets/logoutIcon.svg"></img>
                                                        <div className="gameOptionButton">Log Out</div>
                                                    </div>
                                                </div> */}

                                                {
                                                    this.state.game.rated && !this.state.game.gameEnded ? (
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                            <div onClick={()=>this.endGame(this.state.game, this.state.currentUser)} className="gameLogButtonBox">
                                                                <img id="logImage" src="images/miscallaneous_assets/exitGameIcon.svg"></img>
                                                                <div className="gameOptionButton">Resign Game</div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                            <div onClick={()=>this.exitGame()} className="gameLogButtonBox">
                                                                <img id="logImage" src="images/miscallaneous_assets/exitGameIcon.svg"></img>
                                                                <div className="gameOptionButton">Leave Game</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                
                                                <div onClick={()=>this.toggleActionLog()}  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <div className="gameLogButtonBox">
                                                        <img id="logImage" src="images/miscallaneous_assets/logIcon.svg"></img>
                                                        <div className="gameOptionButton">Game Log</div>
                                                    </div>
                                                </div>
                                            
                                                    
                                            </div>
                                        </div>
                                    )
                                }
                                
                            
                            

                            
                        </div>
                    ) : (
                        
                        <div id="gameMenuBackground">
                            {this.state.currentUser && this.state.currentUser.email!=undefined ? (
                            <div id="gameMenuContainer">
                                <div className="generalMenuBox">
                                    <h5 className="menuLabels" onClick={()=>this.openProfile()}>Profile</h5>
                                    {
                                        this.state.showLeaderboard ? (
                                            <h5 className="menuLabels">Leaderboard</h5>
                                        ) : (
                                            <h5 className="menuLabels" onClick={()=>this.openLeaderboard()}>Leaderboard</h5>
                                        )
                                    }
                                    <a style={{"color":"black", "textDecoration":"none"}} href='/Citadels_Rules.pdf' target='_blank'><h5 className="menuLabels">Rules</h5></a>
                                    <a style={{"color":"black", "textDecoration":"none"}} href='https://discord.gg/ZWHJKcT' target='_blank'><h5 className="menuLabels">DISCORD</h5></a>
                                    <h5 id="gameInvitesTitle" className='gameInvitesTitleMenu' onClick={()=>{this.viewGameInvites()}}>Game Invites <span className="counterContainer">{this.state.receivedChallengesList.length}</span></h5>

                                    

                                </div>
                                <hr></hr>

                                {
                                    this.state.showProfile && (
                                        <div id="profileBox">
                                            <h3><u>Profile</u></h3>
                                            <div id="profileInnerBox">
                                                <h3><strong>{this.state.currentUser.username.substr(0,8)}</strong></h3>
                                                <h4>Rating: <strong><i>{this.state.currentUser.rating ? this.state.currentUser.rating.slice(-1)[0] : "-"}</i></strong></h4>
                                                <hr></hr>
                                                <h5><span className="wins">Wins:</span> <strong><i>{this.state.currentUser.wins ? this.state.currentUser.wins: "-"}</i></strong></h5>
                                                <h5><span className="losses">Losses:</span> <strong><i>{this.state.currentUser.losses ? this.state.currentUser.losses: "-"}</i></strong></h5>
                                                <h5><span className="draws">Draws:</span> <strong><i>{this.state.currentUser.draws ? this.state.currentUser.draws: "-"}</i></strong></h5>
                                                <hr></hr>
                                                <h5>Account Created: <strong><i>{this.state.fireuser.metadata.creationTime}</i></strong></h5>
                                                <h5>Last Login: <strong><i>{this.state.fireuser.metadata.lastSignInTime}</i></strong></h5>

                                            </div>
                                            <button className="btn deleteGame" onClick={()=>this.setState({showProfile:false})}>Close</button>
                                        </div>
                                        
                                    )
                                }

                                {
                                    this.state.showLeaderboard && (
                                        <div id="profileBox">
                                            <button className="btn deleteGame" onClick={()=>this.setState({showLeaderboard:false})}>Close</button>

                                            <h3><u>Top Ranked Players</u></h3>
                                            <h6>Total Players: {this.state.totalPlayers}</h6>
                                            <div id="profileInnerBox">
                                                <table id="leaderTable">
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>Name</th>
                                                    <th>Rating</th> 
                                                    <th>Wins/Losses/Draws</th>
                                                </tr>
                                                <hr></hr>
                                                {
                                                    this.state.leaderboardPlayers.length>0 ? ( this.state.leaderboardPlayers.map((player,index)=>{
                                                        if(player.email === this.state.currentUser.email){
                                                            return <tr className="leaderboardPlayerHighlight">
                                                                <th>{index+1}.</th>
                                                                <th>{player.username.substr(0,10)}</th>
                                                                <th>{player.rating.slice(-1)[0]}</th>
                                                                <th>{`${player.wins}/${player.losses}/${player.draws}`}</th>
                                                            </tr>
                                                        }else{
                                                            return <tr className="leaderboardPlayer">
                                                                <th>{index+1}.</th>
                                                                <th>{player.username.substr(0,10)}</th>
                                                                <th>{player.rating.slice(-1)[0]}</th>
                                                                <th>{`${player.wins}/${player.losses}/${player.draws}`}</th>
                                                            </tr>
                                                        }
                                                    })
                                                    ) : (
                                                        <h3 style={{"margin":"0 auto"}}>LOADING...</h3>
                                                    )
                                                }
                                                </table>

                                            </div>
                                        </div>
                                        
                                    )
                                }

                                {
                                    this.state.showGameInvites && (
                                        <div id="profileBox">
                                             <h3><u>Game Invites</u></h3>
                                             <h5 style={{"color":"red"}}>{this.state.error}</h5>
                                            <div id="profileInnerBox">
                                                {this.state.newChallenge && <h4 id="newChallengeText">New Game Challenge!</h4>}

                                                {
                                                    !this.state.receivedChallengesList.length>0 ? (
                                                        <h4 >No Game Invites</h4>
                                                    ) : ( 
                                                        <div id="gameInvitesContainer">{
                                                        this.state.receivedChallengesList.map((challenge)=>{
                                                            return <div className="gameInviteBox">
                                                                    <div className="gameInviteInnerBox">
                                                                        <h4><strong>{challenge.challenger.substr(0,8)}</strong></h4>
                                                                        <h6>Rating: {challenge.rating}</h6>
                                                                    </div>
                                                                    <div className="gameInviteInnerBox">
                                                                        Mode: {challenge.game.rated ? (<h4 className="rankedLabelInvite">Ranked</h4>) : (<h4 className="casualLabelInvite">Casual</h4>)}
                                                                    </div>
                                                                    <div className="gameInviteInnerBox">
                                                                        <h6>Max Players: </h6>
                                                                        <h4>{challenge.game.numberOfPlayers}</h4>
                                                                    </div>
                                                                    <div className="gameInviteInnerBox">
                                                                        <div id="acceptButton" onClick={()=>this.joinGame(null, challenge.game.gameId)}>Accept</div>
                                                                        <div id="declineButton" onClick={()=>{this.removeChallenge(challenge.game.gameId)}}>Decline</div>
                                                                    </div>
                                                                </div>
                                                        })
                                                        }</div>
                                                    )
                                                }
                                                <button className="btn deleteGame" onClick={()=>this.closeGameInvites()}>Close</button>
                                            </div>
                                        </div>
                                       

                                    )
                                }

                                {
                                    this.state.inLobby ? (
                                        <div>
                                            <h1>Lobby</h1>
                                            <button className="menuButtons deleteGame" onClick={()=>this.cancelGame()}>Exit</button>

                                            <div id="publicGameBox">

                                                {
                                                    this.state.publicGames && this.state.publicGames.length>0 && this.state.publicGames.map((game)=>{
                                                        return <div className="publicGameContainer">
                                                            {
                                                                game.rated ? (
                                                                    <h4 className="rankedLabel">Ranked</h4>
                                                                ) : (
                                                                    <h4 className="casualLabel">Casual</h4>
                                                                )
                                                            }
                                                            <div className="publicGameInnerContainer">
                                                                <h4 className="numPlayers"><strong>{game.players.length}/{game.numberOfPlayers}</strong> P</h4>
                                                                <h4 className="hostLabel">Host: </h4>
                                                                <h4 style={{"marginTop":"6px"}}>
                                                                {
                                                                    game.rated ? (
                                                                        <span><strong>{game.players[0].username.substr(0,8)}</strong>(<i>{game.players[0].rating.slice(-1)[0]}</i>)</span>
                                                                    ) : (
                                                                        <strong>{game.players[0].username.substr(0,8)}</strong>
                                                                    )
                                                                }
                                                                </h4>
                                                                <button className="menuButtons findMatch" onClick={()=>this.joinGame(null, game.gameId)}>Join</button>
                                                               
                                                            </div>
                                                            {/* {
                                                                this.state.error && (<strong style={{"color":"crimson"}}>{this.state.error}</strong>)
                                                            } */}
                                                        </div>
                                                    })
                                                    
                                                }
                                                {
                                                    this.state.publicGames.length===0 && <h4>No open public games...Create a public game so others can join!</h4>
                                                }
                                            </div>
                                        </div>

                                    ) : (
                                        <div>
                                        
                                       

                                        {
                                            this.state.creatingGame ? (
                                                <div>{
                                                this.state.settingUpGame ? (
                                                    <div>
        
                                                        {!this.state.game.useBot && !this.state.game.useMatchmaking && (
                                                            <div id="useTimerBox">
                                                                <h5 className="useTimerBoxInner" style={{"marginTop":"12px"}}>Number of Players </h5>
                                                                <div className="useTimerBoxInner">
                                                                {this.state.dropDownLocked ? (
                                                                    <Dropdown className="dropDown" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} disabled>
                                                                        <DropdownToggle caret className="dropDown" >
                                                                            {this.state.numberOfPlayers}
                                                                        </DropdownToggle>
                                                                        
                                                                    </Dropdown>
                                                                ) : (
                                                                    <Dropdown className="dropDown" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                                                        <DropdownToggle caret className="dropDown" >
                                                                            {this.state.numberOfPlayers}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropDown" >
                                                                            <DropdownItem onClick={()=>{this.setState({numberOfPlayers: 2})}}>2</DropdownItem>
                                                                            <DropdownItem onClick={()=>{this.setState({numberOfPlayers: 3})}}>3</DropdownItem>
                                                                            <DropdownItem onClick={()=>{this.setState({numberOfPlayers: 4})}}>4</DropdownItem>
                                                                            <DropdownItem onClick={()=>{this.setState({numberOfPlayers: 5})}}>5</DropdownItem>
                                                                            <DropdownItem onClick={()=>{this.setState({numberOfPlayers: 6})}}>6</DropdownItem>
                                                                            <DropdownItem onClick={()=>{this.setState({numberOfPlayers: 7})}}>7</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                )
                                                                    
                                                                }
                                                                
                                                                </div>
                                                            </div>
                                                        )}
                                                        {!this.state.game.useBot && !this.state.game.useMatchmaking && (
                                                            <div id="useTimerBox">
                                                                <h5 className="useTimerBoxInner">Private Game </h5>
                                                                <div className="useTimerBoxInner"><Toggle defaultChecked={this.state.privateGame} onChange={()=>this.handleChange("privateGame", !this.state.privateGame)} /></div>
                                                            </div>
                                                        )}
                                                        {/* {!this.state.game.useBot && !this.state.game.useMatchmaking && (
                                                            <div id="useTimerBox">
                                                                <h5 className="useTimerBoxInner">Ranked (2p) </h5>
                                                                <div className="useTimerBoxInner"><Toggle defaultChecked={this.state.rated} onChange={()=>this.handleChange("rated", !this.state.rated)} /></div>
                                                            </div>
                                                        )} */}
                                                        {/* {!this.state.game.useBot && !this.state.game.useMatchmaking && (
                                                            <div id="useTimerBox">
                                                                <h5 className="useTimerBoxInner">Use Timer: </h5>
                                                                <div className="useTimerBoxInner"><Toggle defaultChecked={this.state.useTimer} onChange={()=>this.handleChange("useTimer", !this.state.useTimer)} /></div>
                                                            </div>
                                                        )} */}
                                                        <button className="menuButtons startGame" onClick={()=>this.createGame()}>Create Game</button>
                                                        <button className="menuButtons deleteGame" onClick={()=>this.cancelGame()}>Cancel</button>
                                                        
                                                    </div>
                                                ) : (
                                                    
                                                        <div>
                                                            {
                                                                !this.state.game.useBot && (
                                                                    <div>
                                                                        <h5><strong>GAME ID: </strong> {this.state.game && this.state.game.gameId}</h5>
                                                                        <p id="joinCode">Share this code with your friends so that they can join this game</p>
                                                                    </div>
                                                                )
                                                            }
                                                        <div>
                                                    
                                                    
                                                        <h3>Your Game Party</h3>
                                                        {
                                                            !this.state.game.useBot && this.state.viewChat && (
                                                                <div id="gameChatBox">
                                                                    <h4>Chat</h4>
                                                                    {
                                                                        this.state.messages.map(message=>{
                                                                            return <div className="messageBox">
                                                                                <div><strong>{message.name}:</strong></div>
                                                                                <div>{message.message}</div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                    
                                                                    <input id="chatInput" onChange={evt=>this.handleChange("currentChatMessage", evt.target.value)} onKeyPress={this.handleKeyPress} placeholder="type"></input>
                                                                    
                                                                    <button onClick={()=>this.toggleChat()} className="gameLogButtonBox">Close</button>
                                                                    <div style={{ float:"left", clear: "both" }}
                                                                        ref={el => { this.messagesEnd = el }}>
                                                                    </div> 
                                                                </div>
                                                            )
                                                        }
                                                        <div id="playerBox">
                                                            {
                                                                this.state.game && this.state.game.players && this.state.game.players.map((player)=>{
                                                                    return<div>
                                                                        {
                                                                            (player.email === this.state.partyLeader) || (player.email === this.state.game.partyLeader) ? (
                                                                                <h5><strong>&#9734; </strong>{player.username.substr(0,8)}  <i>{this.state.game.rated && (player.rating[0] ? `(${player.rating.slice(-1)[0]})`:"(unrated)")}</i></h5>
                                                                            ) : (
                                                                                <h5>{player.username.substr(0,8)} <i>{this.state.game.rated && (player.rating && player.rating.length>0 ? `(${player.rating.slice(-1)[0]})`:"(unrated)")}</i></h5>
                                                                            )   
                                                                        }
                                                                    </div>
                                                                       
                                                                  
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            this.state.partyLeader && this.state.currentUser!=undefined && this.state.game.partyLeader === this.state.currentUser.email && this.state.game.players.length>=2 && !this.state.game.gameStarted && (
                                                                <div>
                                                                    <button className="menuButtons startGame" onClick={()=>this.startGame()}>Start Game</button>
                                                                    {!this.state.game.useBot && !this.state.game.rated && (
                                                                        <div id="useTimerBox">
                                                                            <h5 className="useTimerBoxInner">Use Timer: </h5>
                                                                            <div className="useTimerBoxInner"><Toggle defaultChecked={this.state.useTimer} onChange={this.handleToggleChange} /></div>
                                                                        </div>
                                                                    )}
                                                                    
                                                                </div>
                                                            )
                                                        }
                                                        <br/><br/><br/><br/>
                                                        {
                                                            this.state.partyLeader && this.state.currentUser!=undefined && this.state.partyLeader === this.state.currentUser.email || this.state.game.partyLeader === this.state.currentUser.email ? (
                                                                <button className="menuButtons deleteGame" onClick={()=>this.deleteGame()}>Delete Game</button>
                                                            ) : (
                                                                <button className="menuButtons leaveGame" onClick={()=>this.leaveGame()}>Leave Game</button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                )
                                                }</div>
                                            
                                            ) : (
                                                <div className="gameMenuInnerContainer" >  
                                                    {/* <button className="menuButtons findMatch" onClick={()=>this.findMatch()}>Find Match <strong>(BETA)</strong></button>
                                                    <div id="menuDivider"></div> */}
                                                    <button className="menuButtons viewLobby" onClick={()=>this.viewLobby()}>View Open Games</button>
                                                    <button className="menuButtons playAI" onClick={()=>this.playAI()}>Play AI</button>
                                                    <button className="menuButtons createGame" onClick={()=>this.setupGame()}>Create Game</button>
                                                    <button className="menuButtons joinGame" onClick={()=>this.joinGame()}>Join Game</button>
                                                    <input id="joinGameInput" onChange={evt=>this.handleChange("gameId", evt.target.value)} placeholder="Enter Game Code"></input>
                                                    {
                                                        this.state.error && (<strong>{this.state.error}</strong>)
                                                    }
                                                    <br/><br/><br/>
                                                    <h5 className="menuButtons logout" onClick={()=>this.logout()}>Log Out</h5>
                                                    
                                                   
                                                </div>
                                            )
                                        }
                                        {/* {
                                            !this.state.inQueue && !this.state.creatingGame && (<button className="menuButtons logout" onClick={()=>this.logout()}>Log Out</button>) 
                                        } */}
                                        </div>
                                    )
                                }

                               
                 
                            </div>
                            ) : (
                                <div id="mainAccountError">
                                    <h3>Error Creating Account. Please create new account, or contact admin on Discord <a href="https://discord.gg/ZWHJKcT" target="_blank">https://discord.gg/ZWHJKcT</a></h3>
                                    <button className="menuButtons logout" onClick={()=>this.logout()}>Log Out</button>
                                </div>
                            )}
                        </div>
                    )
                }

                {
                    this.state.game.gameEnded && !this.state.exitedGame && (
                        <div id="gameResultsBox">
                            <h1 id="gameResultsTitle">Game Results</h1>
                            <hr></hr>
                            <div>
                                {
                                    this.state.game.forfeiter && (
                                        <h1 className="resultsProperty">{this.state.game.forfeiter} resigned</h1>
                                    )

                                }
                                {
                                    this.state.game.players.map(player=>{
                                        let newRating = 0, oldRating = 0, diff = 0, win = 0;
                                        if(this.state.game.rated){
                                            newRating = player.rating[player.rating.length-1];
                                            oldRating = player.rating.length > 0 ? player.rating[player.rating.length-2] : 1200;
                                            diff = newRating-oldRating;
                                            win = diff>0 ? true : false;
                                        }
                                        return<div>
                                            {
                                                this.state.game.winner === player.email ? (
                                                    <div className="resultsBox">
                                                        <h1 className="resultsProperty">(WINNER)</h1>
                                                        <h1 className="resultsProperty">{player.username}</h1>
                                                        <h3 className="resultsProperty">Points: {player.points}</h3>
                                                    </div>
                                                ) : (
                                                    <div className="resultsBox">
                                                        <h1 className="resultsProperty">{player.username}</h1>
                                                        <h3 className="resultsProperty">Points: {player.points}</h3>
                                                        {
                                                            this.state.game.rated && (
                                                                <h3 className="resultsProperty">New Rating: {player.rating.length>0 && newRating} ({win ? <span className="winResult">&#43;{diff}</span> : <span className="lossResult">{diff}</span>})</h3>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                        </div>
                                    })
                                }
                                <button className="menuButtons leaveGame" onClick={()=>this.exitGame()}>Exit Game</button>

                            </div>
                        </div>
                    )
                }
                <div id="chatAndInvitesBox">
                {
                    this.state.game && this.state.game.gameId!="temp" && (
                        <div id="chatTitle">
                            <div onClick={()=>this.toggleChat()} className="chatButtonBox">
                                <img id="chatImage" src="images/miscallaneous_assets/chatIcon.svg"></img>
                                <div className="gameOptionButton">Chat <span className="chatCounter">{this.state.messageCounter}</span></div>
                            </div>
                        </div>
                    )
                }
                {
                    this.state.game && !this.state.game.gameStarted && (
                        <h5 id='gameInvitesTitleFixed' onClick={()=>{this.viewGameInvites()}}>Game Invites <span className="counterContainer">{this.state.receivedChallengesList.length}</span></h5>
                    )
                }
                </div>
                <div id="onlineListContainer">
                    
                        <h5 id="onlineListTitle" onClick={()=>this.toggleOnlineList()}>Currently Online <span className="counterContainer">{this.state.onlineList.filter(user=>user.email!=this.state.fireuser.email).length}</span></h5>
                    
                        {
                        this.state.showOnlineList && (
                            <div id="onlineListBox">
                                {
                                    this.state.onlineList.map((list)=>{
                                       return list.email && list.email!=this.state.fireuser.email && (
                                           <div className="onlineListElement">
                                               <div className="onlineListElementLeft">
                                                <h4>
                                                    {
                                                        list.currentGame == "" ? (
                                                            <img className="onlineSymbol" src="images/miscallaneous_assets/online-green.svg"></img>
                                                        ):(
                                                            <img className="onlineSymbol" src="images/miscallaneous_assets/online-red.svg"></img>
                                                        )
                                                    }
                                                
                                                    {list.username && list.username.substr(0,8)}
                                                </h4>
                                                <h6>Rating: {list.rating && list.rating.length>0 ? (<strong> {list.rating.slice(-1)}</strong>) : (<strong>unrated</strong>)}</h6>
                                               </div>
                                               <div className="onlineListElementRight">
                                                <div>
                                                    {
                                                       this.state.sentChallengesList.includes(list.email) ? (
                                                                <h7 id="challengeSentText">Challenge Sent</h7>
                                                            ) : (
                                                                list.currentGame == "" ? (<img alt="Invite to Game" title="Invite to Game" onClick={()=>{this.sendChallenge(list.email)}} className="swordsIcon" src="images/miscallaneous_assets/plusIcon.svg"></img>)
                                                                : ( 
                                                                <h7 id="inGameText">In Game</h7>
                                                                )
                                                            )

                                                         
                                                        
                                                    }
                                                </div>
                                               </div>
                                               
                                            </div>
                                        
                                       ) 
                                    })  
                                }
                                {
                                    this.state.onlineList.length === 0 && (
                                        <h4>No Users Online</h4>
                                    )
                                }

                            </div>
                        ) 
                    }
                     
                   
              
                </div>

                {/* {
                this.state.showOnlineList ? (
                        <h5 className="menuLabels">Currently Online</h5>
                    ) : (
                        <h5 className="menuLabels" id="onlineListTitle" onClick={()=>this.openOnlineList()}>Currently Online</h5>
                    )
                } */}

                {
                    this.state.currentCharacterInfo && this.state.showCardInfo && (
                        <div id="cardInfoBox">
                            <div>
                                {
                                    <div id="cardInfoInnerBox">
                                        <div>
                                            <img className="characterCard infoCard" src={`images/character_cards${this.state.currentCharacterInfo.img}.PNG`}></img>
                                        </div>
                                        <div id="cardInfoTextBox">
                                            <div>{this.state.currentCharacterInfo.info}</div>
                                        </div>
                                    </div>
                                }
                                <button className="menuButtons closeCardInfo" onClick={()=>this.closeInfoBox()}>Close</button>

                            </div>
                        </div>
                    )
                }

            </div>
          
        );
    }
}

export default Main;