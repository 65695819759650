import React from 'react';
import fire from '../config';
import axios from 'axios';
import firebase from 'firebase';

class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {email: '', password: '', forgotPass: false};

    }

    handleChange = (name, value) => {
        this.setState({[name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.toggleLoading();

        fire.auth().signInWithEmailAndPassword(this.state.email,this.state.password)
            .then((user)=>{

                // if(user.user.emailVerified === false){
                //     this.props.toggleLoading();
                //     this.setState({message: "*Email Not Verified! Verify Email, Refresh page and try again!"})
                // }else{
                //     this.setState({message: ""})
                // }

                this.setState({message: ""})
                fire.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
                    localStorage.setItem("token", idToken);
                    let date = new Date();
                    localStorage.setItem("timeStamp", date);

                  }).catch(function(error) {
                    console.log(error);
                  });

                
            })
            .catch((err) => {
                console.log(err);
                this.setState({message: '*' + err.message})
                this.props.toggleLoading();
            })  
        
        
    }

    handleGoogleAuth = (event) => {
        let provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {

            this.setState({message: ""})
            fire.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
                localStorage.setItem("token", idToken);
                let date = new Date();
                localStorage.setItem("timeStamp", date);

              }).catch(function(error) {
                console.log(error);
              });

            
            
          }).catch(function(error) {
            console.log(error);
            // Handle Errors here.
            alert("auth failed");
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
    }

    handleFacebookAuth = (event) => {
        let provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
            console.log(result.user);

            this.setState({message: ""})
            fire.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
                console.log(idToken);
                localStorage.setItem("token", idToken);
                let date = new Date();
                localStorage.setItem("timeStamp", date);

              }).catch(function(error) {
                console.log(error);
              });

            
            
          }).catch((error) => {
            // console.log(error);
            if(error.code === "auth/account-exists-with-different-credential")
            // Handle Errors here.
            this.setState({message: '*An account with this email already exists, please log in using those credentials.'});
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
    }


    resetPassword = () => {
        fire.auth().sendPasswordResetEmail(this.state.email).then(()=> {
            this.setState({forgotmessage:'Password Reset Email Sent'})
          }).catch((error)=> {
              console.log(error.message);
              let msg = error.message;
                this.setState({forgotmessage:'There is no account associated with this email'});
          });
    }
    
    render(){
        return(
                <div className="loginBox" >

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" value={this.state.email} required onChange={evt=>this.handleChange('email', evt.target.value)} className="form-control" id="emailLogin" aria-describedby="" placeholder="Email"/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" value={this.state.password} required onChange={evt=>this.handleChange('password', evt.target.value)}  className="form-control" id="passwordLogin" placeholder="Password"/>
                    </div>
                    
                    <div className="authMessage">{this.state.message}</div>
                    
                    <div id="forgotPassButton" onClick={()=>this.setState({forgotPass:true})}>Forgot Password?</div>
                    {
                        this.state.forgotPass ? (
                            <div className="forgotPassBox">
                                <input style={{"margin":"3px"}} required value={this.state.email} type="email" onChange={evt=>this.handleChange('email', evt.target.value)} className="form-control" id="emailLogin" aria-describedby="" placeholder="Enter Email"/>
                                {   this.state.forgotmessage === "Password Reset Email Sent" ? (<div style={{"margin":"3px", "color":"green"}} className="authMessage">{this.state.forgotmessage}</div>)
                                    : (<div style={{"margin":"3px"}} className="authMessage">{this.state.forgotmessage}</div>)
                                }
                                <button style={{"margin":"3px"}} type="button" onClick={()=>this.resetPassword()} className="btn brownButton">Reset Password</button>    
                            </div>
                        ) : (
                            <div></div>
                        )
                    }
                    <br/>
                    {/* <div className="btn socialButtons" onClick={evt=>this.handleGoogleAuth(evt)}><img src="images/google-logo.svg" title="Sign Up with Google" width="30px"/><span id="signUpWithGoogle">Sign Up with Google</span></div> */}
                    {/* <div className="btn socialButtons" onClick={evt=>this.handleFacebookAuth(evt)}><img src="images/facebook-logo.svg" title="Sign Up with Facebook" width="30px"/></div> */}
                    {/* <br/><br/> */}
                    <button type="submit" className="btn brownButton">Let's Go!</button>
                    
                </form>



                </div>
        );
           
    }
}



export default Login;