let characterCards = [
    {cardName: "assassin", img: "/assassin", number:1, info:"Announce a character you wish to murder. The murdered character misses their entire turn."},
    {cardName: "thief", img: "/thief", number:2, info:"Announce a character from whom you wish to steal. When the player with this card is revealed, take their gold."},
    {cardName: "magician", img: "/magician", number:3, info:"You may either exchange your cards with another player or discard any number of cards and draw and equal number of cards from the deck."},
    {cardName: "king", img: "/king", number:4, info:"You receive 1 gold for each noble (yellow) district in your city (board). When the King is called, you immediately receive the crown counter."},
    {cardName: "bishop", img: "/bishop", number:5, info:"You receive 1 gold for each religious (blue) district in your city (board). Your districts may not be destroyed by the Warlord."},
    {cardName: "merchant", img: "/merchant", number:6, info:"You receive 1 gold for each trade (green) district in your city (board). When you take an action, you receive 1 extra gold."},
    {cardName: "architect", img: "/architect", number:7, info:"After you take an action, draw 2 extra cards and put both in your hand. You may build up to 3 districts during your turn."},
    {cardName: "warlord", img: "/warlord", number:8, info:"You receive 1 gold for each military (red) district in your city (board). You may pay 1 gold less than the cost of an opponent's district to destroy it."}
]

let districtCards = [
    {cardName:"townHall", img:"/townHall", cost:5, action:null, color:"green", character:"merchant"},
    {cardName:"university", img:"/university", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"dragonGate", img:"/dragonGate", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"watchtower", img:"/watchtower", cost:1, action:"", color:"red", character:"warlord"},
    {cardName:"armory", img:"/armory", cost:3, action:"", color:"purple", character:"wild"},
    {cardName:"keep", img:"/keep", cost:3, action:"", color:"purple", character:"wild"},
    {cardName:"hauntedCity", img:"/hauntedCity", cost:2, action:"", color:"purple", character:"wild"},
    {cardName:"magicSchool", img:"/magicSchool", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"prison", img:"/prison", cost:2, action:"", color:"red", character:"warlord"},
    {cardName:"ballRoom", img:"/ballRoom", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"tradingPost", img:"/tradingPost", cost:2, action:"", color:"green", character:"merchant"},
    {cardName:"greatWall", img:"/greatWall", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"battlefield", img:"/battlefield", cost:3, action:"", color:"red", character:"warlord"},
    {cardName:"bellTower", img:"/bellTower", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"docks", img:"/docks", cost:3, action:"", color:"green", character:"merchant"},
    {cardName:"graveyard", img:"/graveyard", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"temple", img:"/temple", cost:1, action:"", color:"blue", character:"bishop"},
    {cardName:"factory", img:"/factory", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"harbor", img:"/harbor", cost:4, action:"", color:"green", character:"merchant"},
    {cardName:"laboratory", img:"/laboratory", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"church", img:"/church", cost:2, action:"", color:"blue", character:"bishop"},
    {cardName:"hospital", img:"/hospital", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"palace", img:"/palace", cost:5, action:"", color:"yellow", character:"king"},
    {cardName:"smithy", img:"/smithy", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"monastery", img:"/monastery", cost:3, action:"", color:"blue", character:"bishop"},
    {cardName:"treasury", img:"/treasury", cost:4, action:"", color:"purple", character:"wild"},
    {cardName:"market", img:"/market", cost:2, action:"", color:"green", character:"merchant"},
    {cardName:"library", img:"/library", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"fortress", img:"/fortress", cost:5, action:"", color:"red", character:"warlord"},
    {cardName:"lighthouse", img:"/lighthouse", cost:3, action:"", color:"purple", character:"wild"},
    {cardName:"castle", img:"/castle", cost:4, action:"", color:"yellow", character:"king"},
    {cardName:"observatory", img:"/observatory", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"cathedral", img:"/cathedral", cost:5, action:"", color:"blue", character:"bishop"},
    {cardName:"mapRoom", img:"/mapRoom", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"tavern", img:"/tavern", cost:1, action:"", color:"green", character:"merchant"},
    {cardName:"park", img:"/park", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"museum", img:"/museum", cost:4, action:"", color:"purple", character:"wild"},
    {cardName:"poorHouse", img:"/poorHouse", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"manor", img:"/manor", cost:3, action:"", color:"yellow", character:"king"},
    {cardName:"wishingWell", img:"/wishingWell", cost:5, action:"", color:"purple", character:"wild"},
    {cardName:"throneRoom", img:"/throneRoom", cost:6, action:"", color:"purple", character:"wild"},
    {cardName:"quarry", img:"/quarry", cost:5, action:"", color:"purple", character:"wild"}
]


export {characterCards, districtCards}