import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Main from './Main';
import axios from 'axios';
import Spinner from './Spinner';
// import { GetMetricsQueryConstants } from 'watson-developer-cloud/discovery/v1-generated';

class Home extends React.Component {
  
  constructor(props){
    super(props);

    this.state = {
      user:{},
      sideNavOpen: false,
      screenWidth: window.innerWidth
    }
  }

  componentDidMount(){
    this.getUser();
    window.addEventListener('resize', this.onScreenSizeChange);
  }

  getUser = () => {
    axios.get(`db/users/one?email=${this.props.fireuser.email}&idToken=${localStorage.getItem("token")}`)
    .then((response)=>{
        this.setState({user: response.data[0], loading:false})
    }).catch(err=>{
        console.log(err);
    })
  }

  onScreenSizeChange = () => {
    this.setState({screenWidth: window.innerWidth});
  }

  toggleSideNav = () => {
    this.setState({sideNavOpen: !this.state.sideNavOpen});
  }

  render() {
    return(
      <BrowserRouter>
        <div>
            {/* <div id="mainNavTop"><NavBar sideNavOpen={this.state.sideNavOpen} toggleSideNav={this.toggleSideNav} currUser={this.state.user}/></div> */}
              <div id="mainContent" style={{"marginRight":"0"}}>
                {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12"><SideNav toggleSideNav={this.toggleSideNav} currUser={this.state.user}/></div> */}
                <div id="mainContainer"> 
                  <Switch>
                    <Route path="/" render={()=> <Main currUser={this.state.user} fireuser={this.props.fireuser}/>} exact/>
                    
              
                  </Switch>
                  
                </div>
                
              </div>

                 

        </div>
      </BrowserRouter>
    )
  }


}


export default Home;
